export default {
    nav: {
        langSwitchOpen: false,
        profileMenuOpen: false,
        searchOpen: false,
        mobileOpen: false,
        memberstructureNavigationOpen: false,
        memberstructureNavigationOptionsSearch: '',
        watchlistCount: 0,
        unreadMessagesCount: 0
    }
}
