<template>
    <a
        v-bind="{ 'href': getLogoLink }"
        class="logo"
        data-qa="logo-desktop"
        title="Zur Startseite"
    >
        <picture>
            <source srcset="/typo3conf/ext/crefo_portal/Resources/Public/img/logo/without-claim/CR-Bildmarke-RGB.svg" width="26" height="34" media="(max-width: 767px)">
            <source srcset="/typo3conf/ext/crefo_portal/Resources/Public/img/logo/with-claim/meine-CR-RGB.svg">
            <img src="/typo3conf/ext/crefo_portal/Resources/Public/img/logo/with-claim/meine-CR-RGB.svg" alt="Creditreform">
        </picture>
    </a>
</template>

<script>
export default {
    name: 'MainLogo',
    computed: {
        getLogoLink() {
            switch(this.$i18n.locale) {
                case "de_AT":
                    return "/de-at/"
                    break
                case "en_US":
                    return "/en/"
                    break
                case "fr_FR":
                    return "/fr/"
                    break
                case "fr_LU":
                    return "/fr-lu/"
                    break
                default:
                    return "/"
            }

        }
    }
}
</script>

<style scoped lang="less">
@media only screen and (max-width: 767px) {
    .header-nav a.logo {
        left: 15px;
    }
}
@media only screen and (min-width: 768px) {
    .header-nav a.logo {
        margin-left: 0;
        width: 175px;
        left: -15px;
    }
}
@media only screen and (min-width: 1200px) {
    .header-nav a.logo {
        margin-left: 0;
        width: 250px;
        left: -28px;
    }
}
</style>
