export default {
    user: {
        authenticated: false,
        token: null,
        clientLang: 'de_DE',
        user: {
            salutationCode: null,
            title: null,
            genderCode: null,
            birthdate: null,
            department: null,
            position: null,
            telephoneNumber: null,
            preferredLanguage: null,
            givenName: '',
            surname: '',
            email: null,
            password: null,
            company: {
                name: '',
                crefonummer: null
            },
            memberId: null,
            memberName: '',
            membernumber: null,
            id: null,
            user_roles: [],
            memberStructure: null,
            actForMemberId: null,
            actForMemberName: '',
            isMitgliedsbenutzer: false,
            clz: null,
            crMemberuserid: null
        }
    }
}
