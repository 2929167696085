<template>
  <nav
    class="mobile-menu"
    :class="{ 'active': isOpen, 'profile-menu-open': profileOpen }"
  >
    <!-- Profile Menu -->
    <ul class="profile-menu">
      <li
        class="mobile-menu--item nav-link profile-menu--opener"
        @click="toggleProfileMenu"
      >
        <span>
          <span class="company-logo">
            <i
              aria-hidden="true"
              class="crefo-brand-icon icon-building icon-color-blue"
            />
          </span>
          <span class="company-name">{{ memberName }}</span>
          <span class="open-close">
            <i
              aria-hidden="true"
              class="crefo-ui-icon icon-chevron-down"
            />
          </span>
        </span>
      </li>
      <li class="mobile-menu--item nav-link profile-menu--slide">
        <div
          class="company"
          v-if="memberNumber"
        >
          <!-- Erst mit Öffnung für Nichtmitglieder relevant -->
          <!-- <div v-if="memberNumber"> -->
          <div>
            <span class="company-name text-brand-darkest-blue text-bold">
              {{ memberName }}
            </span><br>
            <span class="company-crefonumber text-brand-grey">
              {{ $t('crefonumber.abbrev') }}.: {{ memberNumber }}
            </span>
            <div
              class="edit"
              v-if="userHasRole('CR-CP.UNTERNEHMENSDATEN')"
            >
              <a v-bind="{ 'href': getUnternehmensdatenLink }">
                <span v-if="!isActingForOtherMemberId">
                  {{ $t('profilemenu.companyprofile.edit') }}
                </span>
                <span v-else>
                  {{ $t('profilemenu.companyprofile.view') }}
                </span>
              </a>
              <a
                class="edit"
                v-bind="{ 'href': $i18n.locale === 'en_US' ? '/en/my-profile/?area=business%20data' : ($i18n.locale === 'de_AT' ? '/de-at/mein-profil/?area=unternehmensdaten' : '/mein-profil/?area=unternehmensdaten') }"
              >
                <i
                  v-if="!isActingForOtherMemberId"
                  class="crefo-ui-icon icon-edit-pen icon-color-blue"
                  aria-hidden="true"
                />
                <i
                  v-else
                  class="crefo-ui-icon icon-eye-open icon-color-blue"
                  aria-hidden="true"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="user">
          <div class="profile-picture-holder">
            <i
              class="profile-picture crefo-brand-icon icon-user-circle icon-color-blue"
              aria-hidden="true"
            />
            <div class="user-name">
              <span class="text-brand-darkest-blue text-bold text-ellipsis">
                {{ username }}
              </span>
              <span v-if="newSelfcareLinkLoaded">
                <a
                  v-bind="{ 'href': setSelfcareLink, 'target': !isMitgliedsbenutzer ? '_blank' : '' }"
                >
                  {{ $t('profilemenu.userprofile.edit') }}
                </a>
                <a
                  class="edit"
                  v-bind="{ 'href': setSelfcareLink, 'target': !isMitgliedsbenutzer ? '_blank' : '' }"
                >
                  <i
                    class="crefo-ui-icon icon-edit-pen icon-color-blue"
                    aria-hidden="true"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- Main Menu -->
    <ul v-if="isMitgliedsbenutzer">
      <li
        ref="menu"
        class="mobile-menu--item nav-link"
        v-bind="{ 'class': !item.highlight ? '' : 'highlight' }"
        v-for="(item, i) in mainMenu"
        :key="i"
      >
        <span
          v-if="item.subitems.length"
          :data-qa="item.dataQa"
          @click="toggleSubmenu"
        >
          {{ $t(item.translateKey) }}
        </span>

        <router-link
          v-if="item.isRouterLink && !item.subitems.length"
          v-bind="{ 'to': setRouterLinkByLanguage(item) }"
          :data-qa="item.dataQa"
        >
          <span @click="closeMobileNavigation">{{ $t(item.translateKey) }}</span>
        </router-link>

        <a
          v-if="!item.isRouterLink && !item.subitems.length"
          :data-qa="item.dataQa"
          v-bind="{ 'href': setHrefLinkByLanguage(item) }"
        >
          {{ $t(item.translateKey) }}
        </a>
        <div
          class="submenu-wrapper"
          v-if="item.subitems.length"
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <ul class="submenu">
                  <li
                    @click="toggleSubmenu"
                    class="back"
                  >
                    <span>{{ $t('mobilemenu.back') }}</span>
                  </li>
                </ul>
              </div>
              <div class="col-12">
                <ul class="submenu">
                  <li
                    class="mobile-submenu--item"
                    v-for="(subitem, n) in item.subitems"
                    :key="n"
                    v-if="subitem.show"
                  >
                    <router-link
                      v-if="subitem.isRouterLink"
                      v-bind="{ 'to': setRouterLinkByLanguage(subitem) }"
                      :data-qa="subitem.dataQa"
                    >
                      <span @click="closeMobileNavigation">{{ $t(subitem.translateKey) }}</span>
                    </router-link>
                    <a
                      v-else
                      v-bind="{ 'href': setHrefLinkByLanguage(subitem) }"
                      :target="hrefTarget(subitem)"
                      :data-qa="subitem.dataQa"
                    >
                      <span @click="closeMobileNavigation">{{ $t(subitem.translateKey) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul v-else>
      <li
        ref="menu"
        class="mobile-menu--item nav-link"
        v-bind="{ 'class': !item.highlight ? '' : 'highlight' }"
        v-for="(item, i) in mainMenuRegUser"
        :key="i"
      >
        <span
          v-if="item.subitems.length"
          :data-qa="item.dataQa"
          @click="toggleSubmenu"
        >
          {{ $t(item.translateKey) }}
        </span>
        <a
          v-else
          :data-qa="item.dataQa"
          v-bind="{ 'href': setHrefLinkByLanguage(item) }"
        >
          {{ $t(item.translateKey) }}
        </a>
        <div
          class="submenu-wrapper"
          v-if="item.subitems.length"
        >
          <div class="container">
            <div class="row">
              <div class="col-12">
                <ul class="submenu">
                  <li
                    @click="toggleSubmenu"
                    class="back"
                  >
                    <span>{{ $t('mobilemenu.back') }}</span>
                  </li>
                </ul>
              </div>
              <div class="col-12">
                <ul class="submenu">
                  <li
                    class="mobile-submenu--item"
                    v-for="(subitem, n) in item.subitems"
                    :key="n"
                    v-if="subitem.show"
                  >
                    <router-link
                      v-if="subitem.isRouterLink"
                      v-bind="{ 'to': setRouterLinkByLanguage(subitem) }"
                      :data-qa="subitem.dataQa"
                    >
                      <span @click="closeMobileNavigation">{{ $t(subitem.translateKey) }}</span>
                    </router-link>
                    <a
                      v-else
                      v-bind="{ 'href': setHrefLinkByLanguage(subitem) }"
                      :target="hrefTarget(subitem)"
                      :data-qa="subitem.dataQa"
                    >
                      <span @click="closeMobileNavigation">{{ $t(subitem.translateKey) }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <!-- Service Menu -->
    <ul class="service-menu--mobile">
      <li
        class="mobile-menu--item mobile-service-menu--item nav-link bg-brand-light-grey"
        v-for="(item, i) in serviceMenu"
        v-if="item.show"
        :key="i"
      >
        <a
          v-if="item.href.de !== 'logout'"
          v-bind="{ 'href': setHrefLinkByLanguage(item) }"

          :data-qa="item.dataQa"
        >
          <span @click="closeMobileNavigation">{{ $t(item.translateKey) }}</span>
        </a>
        <a
          v-else
          href="#"
          :data-qa="item.dataQa"
        >
          <span @click.prevent="logout">{{ $t(item.translateKey) }}</span>
        </a>
      </li>
    </ul>

    <!-- Language Switch -->
    <ul class="language-switcher--mobile">
      <li class="mobile-menu--item mobile-service-menu--item nav-link bg-brand-light-grey">
        <span>
          <span
            v-for="(lang, i) in languages"
            :key="i"
          >
            <span
              v-bind="{ 'class': $i18n.locale === lang.code ? 'text-bold' : 'text-regular' }"
              @click="setLanguage(lang.code)"
            >
              {{ lang.name }}
            </span>
            <span
              v-if="i < languages.length-1"
              class="text-bold devider"
            >&nbsp;|&nbsp;</span>
          </span>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
import { dispatchEvent } from '@/helper/polyfill'
import { getKeycloakEnvConfigAndExecute, getUserManagementSelfcareUrl } from '@/keycloak-config'
import { clearAllStorages } from '@/helper/storage'
import { standardInkassoSubitems, getCustomInkassoLinksForClz } from "@/components/navigation/partials/inkasso-sub-menu-items"

export default {
    name: 'MobileMenu',
    data () {
        return {
            isOpen: false,
            profileOpen: false,
            languages: [
                {
                    code: 'de_DE',
                    name: 'DE'
                },
                {
                    code: 'de_AT',
                    name: 'AT'
                },
                {
                    code: 'en_US',
                    name: 'EN'
                },
                {
                    code: 'fr_FR',
                    name: 'FR'
                },
                {
                    code: 'fr_LU',
                    name: 'LU'
                }
            ],
            newSelfcareLink: '',
            newSelfcareLinkLoaded: false,
            inkassoSubitems: standardInkassoSubitems
        }
    },
    computed: {
        ...mapState([
            'navModule',
            'featureModule'
        ]),
        getUnternehmensdatenLink() {
            let ret = ''
            switch(this.$i18n.locale) {
                case 'en_US':
                    ret = '/en/my-profile/?area=business%20data'
                    break
                case 'de_AT':
                    ret = '/de-at/mein-profil/?area=unternehmensdaten'
                    break
                case 'fr_FR':
                    ret = '/fr/mon-profil/?area=business%20data'
                    break
                case 'fr_LU':
                    ret = '/fr-lu/mon-profil/?area=business%20data'
                    break
                default:
                    ret = '/mein-profil/?area=unternehmensdaten'
            }
            return ret
            //return this.$i18n.locale === 'en_US' ? '/en/my-profile/?area=business%20data' : (this.$i18n.locale === 'de_AT' ? '/de-at/mein-profil/?area=unternehmensdaten' : '/mein-profil/?area=unternehmensdaten')
        },
        setRouterLinkByLanguage() {
            return (subitem) => {
                if (this.$i18n.locale === 'en_US' && subitem.href.en) {
                    return subitem.href.en
                } else if (this.$i18n.locale === 'de_AT' && subitem.href.at) {
                    return subitem.href.at
                } else if (this.$i18n.locale === 'fr_FR' && subitem.href.fr) {
                    return subitem.href.fr
                } else if (this.$i18n.locale === 'fr_LU' && subitem.href.lu) {
                    return subitem.href.lu
                } else if (subitem.href.de) {
                    return subitem.href.de
                } else {
                    return '#'
                }
            }
            // Backup falls Probleme auftreten
            // $i18n.locale === 'en_US' ? subitem.href.en : (subitem.href.at && $i18n.locale === 'de_AT' ? subitem.href.at : subitem.href.de)
        },
        setHrefLinkByLanguage() {
            return (subitem) => {
                if(this.$i18n.locale === 'en_US') {
                    return subitem.href.en
                } else if(this.$i18n.locale === 'de_AT') {
                    return (subitem.href.at ? subitem.href.at : '/de-at' + subitem.href.de)
                } else if(subitem.href.fr && this.$i18n.locale === 'fr_FR') {
                    return subitem.href.fr
                } else if(subitem.href.lu && this.$i18n.locale === 'fr_LU') {
                    return subitem.href.lu
                } else {
                    return subitem.href.de
                }
            }
            // Backup falls Probleme auftreten
            //$i18n.locale === 'en_US' ? subitem.href.en : ($i18n.locale === 'de_AT' ? (subitem.href.at ? subitem.href.at : '/de-at' + subitem.href.de) : subitem.href.de)
        },
        setSelfcareLink() {
            if(!this.isMitgliedsbenutzer) {
                return this.newSelfcareLink
            } else {
                if(this.$i18n.locale === 'en_US') {
                    return '/en/my-profile/?area=user%20data'
                } else if (this.$i18n.locale === 'de_AT') {
                    return '/de-at/mein-profil/?area=nutzerdaten'
                } else if (this.$i18n.locale === 'fr_FR') {
                    return '/fr/mon-profil/?area=user%20data'
                } else if (this.$i18n.locale === 'fr_LU') {
                    return '/fr-lu/mon-profil/?area=user%20data'
                } else {
                    return '/mein-profil/?area=nutzerdaten'
                }
            }
        },
        featureDrdIsActive () {
            return this.$store.state.featureModule.drd.active
        },
        username: function () {
            return `${this.$store.state.userModule.user.user.givenName} ${this.$store.state.userModule.user.user.surname}`
        },
        memberName: function () {
            if (this.$store.state.userModule.user.user.actForMemberName.length) {
                return this.$store.state.userModule.user.user.actForMemberName
            }
            if (this.$store.state.userModule.user.user.memberName.length) {
                return this.$store.state.userModule.user.user.memberName
            }
            return this.username
        },
        memberNumber: function () {
            return this.$store.state.userModule.user.user.actForMemberId ||
                this.$store.state.userModule.user.user.memberId
        },
        isActingForOtherMemberId: function () {
            return this.$store.state.userModule.user.user.actForMemberId !== null
        },
        serviceMenu: function () {
            return [
                {
                    translateKey: 'servicemenu.about',
                    dataQa: 'navbar__item-11',
                    show: true,
                    href: {
                        de: '/ueber-creditreform/',
                        en: '/en/about-creditreform/',
                        fr: '/fr/a-propos-de-creditreform/',
                        lu: '/fr-lu/a-propos-de-creditreform/'
                    }
                },
                {
                    translateKey: 'servicemenu.contact',
                    dataQa: 'navbar__item-13',
                    show: this.$store.state.userModule.user.authenticated && this.$store.state.userModule.user.user.isMitgliedsbenutzer,
                    href: {
                        de: '/kontakt/',
                        en: '/en/contact/',
                        fr: '/fr/contact',
                        lu: '/fr-lu/contact/'
                    }
                },
                {
                    translateKey: 'servicemenu.mobile.logout',
                    dataQa: 'navbar__item-logout',
                    show: this.$store.state.userModule.user.authenticated,
                    href: {
                        de: 'logout',
                        en: 'logout',
                        fr: 'Déconnexion',
                        lu: 'Déconnexion'
                    }
                }
            ]
        },
        mainMenu: function () {
            const getMahnwesenLink = () => {
                let subdomain = location.hostname.replace('cp.', '.').replace('meine.creditreform.de', '')
                if (subdomain === '') {
                    subdomain = 'www.'
                } else if (subdomain === 'sunzinet.') {
                    subdomain = 'sedev.'
                }
                return `https://${subdomain}creditreform-mahnwesen.de/de`
            }
            return [
                {
                    translateKey: 'featurenav.inbox',
                    dataQa: 'tabCategory-inbox',
                    highlight: true,
                    isRouterLink: true,
                    href: {
                        de: "/portal/posteingang",
                        en: "/portal/posteingang",
                        fr: "/portal/posteingang",
                        lu: "/portal/posteingang"
                    },
                    subitems: []
                },
                {
                    translateKey: 'featurenav.transactions',
                    dataQa: 'tabCategory-transactions',
                    highlight: true,
                    isRouterLink: false,
                    href: {
                        de: '/vorgaenge',
                        en: '/en/files/',
                        fr: "/fr/operations/",
                        lu: "/fr-lu/operations/"
                    },
                    subitems: []
                },
                {
                    translateKey: 'featurenav.watchlist',
                    dataQa: 'tabCategory-watchlist',
                    highlight: true,
                    isRouterLink: false,
                    href: {
                        de: '/bonitaetsauskunft/watchlist-unternehmen',
                        en: '/en/business-reports/watchlist/',
                        fr: "/fr/solvabilite/watchlist-entreprises/",
                        lu: "/fr-lu/solvabilite/watchlist-entreprises/"
                    },
                    subitems: []
                },
                {
                    translateKey: 'mainmenu.businessreports.title',
                    dataQa: 'tabCategory-6',
                    subitems: [
                        {
                            translateKey: 'mainmenu.businessreports.subitem.reportb2b',
                            dataQa: 'submenuItem-27',
                            show: true,
                            isRouterLink: false,
                            href: {
                                de: '/bonitaetsauskunft/bonitaet-einer-firma-pruefen/',
                                en: '/en/business-reports/business-reports/',
                                fr: "/fr/solvabilite/verifier-la-solvabilite-dune-entreprise/",
                                lu: "/fr-lu/solvabilite/verifier-la-solvabilite-dune-entreprise/"
                            }
                        },
                        {
                            translateKey: 'mainmenu.businessreports.subitem.reportb2c',
                            dataQa: 'submenuItem-201',
                            show: true,
                            isRouterLink: false,
                            href: {
                                de: '/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/',
                                at: '/de-at/bonitaetsauskunft/bonitaet-einer-privatperson-aus-deutschland-pruefen/',
                                en: '/en/business-reports/reports-on-private-persons/',
                                fr: "/fr/solvabilite/verifier-la-solvabilite-dun-particulier/",
                                lu: "/fr-lu/solvabilite/verifier-la-solvabilite-dun-particulier/"
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.debtcollection.title',
                    dataQa: 'tabCategory-7',
                    subitems: this.inkassoSubitems
                },
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey: 'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-270',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/meine-bonitaet',
                                en: '/portal/meine-bonitaet',
                                at: '/portal/meine-bonitaet',
                                fr: '/portal/meine-bonitaet',
                                lu: '/portal/meine-bonitaet'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'submenuItem-ubo',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/ubo/',
                                en: '/portal/ubo/',
                                fr: '/portal/ubo/',
                                lu: '/portal/ubo/',
                                at: '/portal/ubo/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'submenuItem-myesg',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/myesg/',
                                en: '/portal/myesg/',
                                fr: '/portal/myesg/',
                                lu: '/portal/myesg/',
                                at: '/portal/myesg/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.companydata',
                            dataQa: 'submenuItem-companydata',
                            show: this.userHasRole('CR-CP.UNTERNEHMENSDATEN'),
                            isRouterLink: false,
                            href: {
                                de: '/mein-profil/?area=unternehmensdaten',
                                en: '/en/my-profile/?area=business%20data',
                                fr: '/fr/mon-profil/?area=business%20data',
                                lu: '/fr-lu/mon-profil/?area=business%20data'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.userdata',
                            dataQa: 'submenuItem-userdata',
                            show: this.userHasRole('CR-CP.MITGLIEDSDATEN'),
                            isRouterLink: false,
                            href: {
                                de: '/mein-profil/?area=mitgliedsdaten',
                                en: '/en/my-profile/?area=membership%20data',
                                fr: '/fr/mon-profil/?area=membership%20data',
                                lu: '/fr-lu/mon-profil/?area=membership%20data'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.mahnwesen',
                            dataQa: 'submenuItem-mahnwesen',
                            show: this.isNotAustrian && this.isNotLuxemburg,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: {
                                de: getMahnwesenLink(),
                                en: getMahnwesenLink()
                            }
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive,
                            isRouterLink: true,
                            href: {
                                de: '/portal/drd',
                                en: '/portal/drd'
                            }
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.crefoaddress',
                            dataQa: 'submenuItem-crefoaddress',
                            show: true,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: {
                                at: 'https://crefoaddress.de',
                                de: 'https://crefoaddress.de',
                                en: 'https://crefoaddress.de',
                                fr: 'https://crefoaddress.de',
                                lu: 'https://crefoaddress.de'
                            }
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.magazine',
                            dataQa: 'submenuItem-magazine',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/creditreform-magazin',
                                en: '/portal/creditreform-magazin',
                                fr: '/portal/creditreform-magazin',
                                lu: '/portal/creditreform-magazin'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.auswertungen.title',
                    dataQa: 'tabCategory-auswertungen',
                    isRouterLink: false,
                    show: this.userHasCrMemberuserid() && !this.isActFor,
                    subitems: [],
                    href: {
                        de: '/portal/auskunftsexport',
                        en: '/portal/auskunftsexport',
                        fr: '/portal/auskunftsexport',
                        lu: '/portal/auskunftsexport'
                    }
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: false,
                    subitems:[],
                    href: {
                        de: '/portal/downloadbereich',
                        en: '/portal/downloadbereich',
                        fr: '/portal/downloadbereich',
                        lu: '/portal/downloadbereich'
                    }
                }
            ]
        },
        mainMenuRegUser: function () {
            return [
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey: 'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-meine-bonitaet',
                            show: true,
                            highlight: true,
                            isRouterLink: true,
                            href: {
                                de: "/portal/meine-bonitaet",
                                en: "/portal/meine-bonitaet",
                                at: "/portal/meine-bonitaet",
                                fr: "/portal/meine-bonitaet",
                                lu: "/portal/meine-bonitaet"
                            },
                            subitems: []
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'tabCategory-ubo',
                            show: true,
                            highlight: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/ubo/',
                                en: '/portal/ubo/',
                                at: '/portal/ubo/',
                                fr: '/portal/ubo/',
                                lu: '/portal/ubo/'
                            },
                            subitems: []
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'tabCategory-myesg',
                            show: true,
                            highlight: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/myesg/',
                                en: '/portal/myesg/',
                                fr: '/portal/myesg/',
                                lu: '/portal/myesg/',
                                at: '/portal/myesg/'
                            },
                            subitems: []
                        },
                    ]
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive,
                            isRouterLink: true,
                            href: {
                                de: '/portal/drd',
                                en: '/portal/drd'
                            }
                        },
                    ]
                },  
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: false,
                    subitems:[],
                    href: {
                        de: '/portal/downloadbereich',
                        en: '/portal/downloadbereich',
                        fr: '/portal/downloadbereich',
                        lu: '/portal/downloadbereich'
                    }
                }
            ]
        },
        isNotAustrian: function () {
            return this.$i18n.locale !== 'de_AT'
        },
        isNotLuxemburg () {
            return this.$i18n.locale !== 'fr_LU'
        },
        isMitgliedsbenutzer: function () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        isActFor () {
            return this.$store.state.userModule.user.user.actForMemberId
        }
    },
    methods: {
        closeMobileNavigation () {
            this.$store.dispatch('navModule/closeMobile')
            if (document.body.classList.contains('backdrop-active')) {
                document.body.classList.remove('backdrop-active')
            }
            if (document.body.classList.contains('mobile-menu-opened')) {
                document.body.classList.remove('mobile-menu-opened')
            }
        },
        toggleSubmenu (evt) {
            this.profileOpen = false
            document.querySelector('.mobile-menu').scrollTo(0, 0)
            let $el = evt.target
            while (!$el.classList.contains('mobile-menu--item')) {
                $el = $el.parentNode
            }
            $el.classList.toggle('submenu-opened')
        },
        toggleProfileMenu () {
            this.profileOpen = !this.profileOpen
        },
        async getNewSelfcareLink () {
            let res = await getUserManagementSelfcareUrl()
            this.newSelfcareLinkLoaded = true
            this.newSelfcareLink = res
        },
        setLanguage (langCode) {
            document.cookie = `CAS_PREFERRED_LANGUAGE=${langCode}; SameSite=None; Secure;domain=creditreform.de;path=/`
            document.cookie = `KEYCLOAK_LOCALE=${langCode.split('_')[0]}; SameSite=None; Secure;domain=creditreform.de;path=/`
            this.$i18n.locale = langCode
            if (document.body.classList.contains('backdrop-active')) {
                document.body.classList.remove('backdrop-active')
            }
            this.$store.dispatch('userModule/setLanguage', langCode)
            window.setTimeout(() => {
                dispatchEvent('languageChanged')
            }, 250)
        },
        userHasCrMemberuserid () {
            return this.$store.state.userModule.user.user.crMemberuserid ? true : false
        },
        userHasRole (roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(roleName)
        },
        userHasOneOfRoles (roleNames) {
            return this.$store.state.userModule.user.user.user_roles.some(role => roleNames.includes(role))
        },
        logout () {
            const doLogout = (config) => {
                clearAllStorages()

                dispatchEvent('loggedOut')

                const keycloakExists = setInterval(() => {
                    if (window.keycloak && window.keycloak.logout && typeof window.keycloak.logout === 'function') {
                        clearInterval(keycloakExists)
                        window.keycloak.logout({ redirectUri: this.$router.getLogoutUrl() })
                        window.setTimeout(() => {
                            window.keycloakStatusChecked = true
                        }, 1000)
                    }
                }, 200)
            }

            getKeycloakEnvConfigAndExecute(doLogout)
        },
        hrefTarget (el) {
            return el.isExternalLink ? '_blank' : ''
        }
    },
    watch: {
        isOpen (val, oldVal) {
            // We want to reset the menu state, whenever the menu gets closed
            if (!val) {
                this.profileOpen = false
                document.querySelectorAll('.mobile-menu--item').forEach((item) => {
                    item.classList.remove('submenu-opened')
                })
            }
        }
    },
    mounted () {
        if (this.$store.state.userModule.user.authenticated) {
            this.getNewSelfcareLink()
        } else {
            this.$store.watch((state) => state.userModule.user.authenticated, (newProps, oldProps) => {
                if (newProps) {
                    this.getNewSelfcareLink()
                }
            })
        }

        const userClz = this.$store.state.userModule.user.user.clz
        if (userClz !== null) {
            getCustomInkassoLinksForClz(userClz).then((subitems) => { this.inkassoSubitems = subitems })
        } else {
            this.$store.watch((state) => state.userModule.user.user.clz, (newClz, _oldClz) => {
                if (newClz) {
                    getCustomInkassoLinksForClz(newClz).then((subitems) => { this.inkassoSubitems = subitems })
                }
            })
        }

        this.$store.watch((state) => state.navModule.nav.mobileOpen, (newProps, oldProps) => {
            this.isOpen = newProps
        })

        this.$store.watch((state) => state.userModule.user.clientLang, (newProps, oldProps) => {
            if (newProps && newProps !== oldProps) {
                this.setLanguage(newProps)
            }
        })
    }
}
</script>

<style scoped lang="less">
.mobile-menu {
    z-index: 1001;
    transition: all 0.5s ease-in-out;
    // transition: right .5s cubic-bezier(.59,.03,.17,1.37) 0s;
    // background: #F5F6F6;
    background: #fff;
    height: 100vh;
    top: 50px;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    right: -100%;
    border-top: 1px solid #e6e5e5;

    &.active {
        right: 0;
    }

    .text-regular {
        font-weight: normal !important;
    }

    .language-switcher--mobile {
        .devider {
            margin: 0 8px;
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;
        // height: 100%;
        height: auto;
        position: relative;

        li {
            background: #ffffff;
            flex: 1;
            display: flex;
            align-items: center;
            padding: 0;
            border-bottom: 1px solid #e6e5e5;
            height: 50px;

            // &:last-of-type {
            //     border-bottom: none;
            // }

            &.highlight {
                background: #009ee3;
                &.mobile-menu--item {
                    &> span, &> a {
                        color: #fff;
                    }
                }
            }

            &.mobile-service-menu--item {
                border-bottom: 1px solid #fff;
            }

            .back {
                color: #4c4c4c;
                text-transform: uppercase;
                border-bottom: 1px solid #e6e5e5;

                span {
                    padding-left: 30px;
                }

                &:before {
                    font-family: 'crefo-ui-icons' !important;
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    background-image: none !important;
                    text-shadow: none !important;
                    font-size: 23px;
                    content: "\e904";
                    position: absolute;
                    left: 15px;
                }
            }

            &.mobile-menu--item:not(.mobile-service-menu--item):not(.profile-menu--opener) {
                &> span, &> a {
                    &:after {
                        font-family: 'crefo-ui-icons' !important;
                        speak: none;
                        font-style: normal;
                        font-weight: normal;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        background-image: none !important;
                        text-shadow: none !important;
                        font-size: 23px;
                        content: "\e903";
                        position: absolute;
                        right: 15px;
                    }
                }
            }

            &.mobile-menu--item {
                height: 50px;

                &.mobile-service-menu--item {
                    &> span, &> a {
                        color: #4c4c4c;
                    }
                }

                &> span, &> a {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    width: 100%;
                    padding: 0 15px;
                    position: relative;
                    color: #009ee3;
                    font-weight: bold;
                }

                &.submenu-opened {
                    .submenu-wrapper {
                        right: 0;
                    }
                }

                .submenu-wrapper {
                    width: 100%;
                    position: absolute;
                    height: calc(100% + 250px);
                    top: 0;
                    background: #FFF;
                    z-index: 1;
                    right: -2000px;
                    transition: all 0.4s;

                    > .container {
                        padding: 0;
                    }

                    ul {
                        padding-top: 0;

                        li {
                            height: 50px;
                            font-weight: bold;
                            padding: 0 15px;;

                            a {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu {
    &.profile-menu-open {
        .profile-menu {
            &--opener {
                .open-close {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }

            &--slide {
                height: 210px !important;
                align-items: center !important;

                > div {
                    align-items: center !important;
                }
            }
        }
    }

    .profile-menu {
        &--opener {
            height: 100px !important;

            i {
                vertical-align: middle;
            }

            .company-logo {
                border: 1px solid #edece5;
                padding: 12px 8px 16px;

                i {
                    font-size: 32px;
                }
            }

            .company-name {
                margin: 0 15px;
            }

            .open-close {
                i {
                    display: inline-block;
                    transition: transform .3s ease-in-out;
                    position: absolute;
                    right: 15px;
                    top: 39px;
                }
            }
        }

        &--slide {
            height: 0 !important;
            transition: all 0.5s;
            align-items: baseline !important;
            padding: 0 15px;
            flex-direction: column;

            > div {
                align-items: end !important;
                transition: all 0.5s;
            }

            .company {
                text-align: left;
                width: 100%;
                padding-bottom: 15px;
                border-bottom: 1px solid #edece5;
                margin-bottom: 15px;
                margin-top: 20px;

                &> div {
                    padding-left: 67px;
                }

                &-name {
                    font-size: 16px;
                    margin-bottom: 5px;
                    display: inline-block;
                }

                .edit {
                    position: relative;
                    padding-top: 15px;
                    margin-bottom: 10px;
                }
                a.edit {
                    position: absolute;
                    right: 0;
                    top: -2px;
                }
            }

            & > .user:first-child {
                margin-top: 15px;
            }

            .user {
                text-align: left;
                padding-bottom: 15px;
                // border-bottom: 1px solid #edece5;
                margin-bottom: 15px;
                width: 100%;

                .profile-picture-holder {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: flex-start;
                }

                .profile-picture {
                    border: 1px solid #edece5;
                    border-radius: 50%;
                    padding: 10px;
                    font-size: 32px;
                    display: inline-block;
                    margin-right: 15px;
                }

                &-name {
                    font-size: 14px;
                    display: inline-block;
                    width: calc(100% - 69px);

                    &> span:first-of-type {
                        margin-bottom: 5px;
                    }

                    &> span {
                        position: relative;
                        display: inline-block;
                        width: 100%;
                    }

                    a {
                        display: inline-block;
                        width: 100%;
                        padding-right: 35px;
                    }

                    a.edit {
                        display: inline;
                        padding-right: 0;
                        width: auto;
                        position: absolute;
                        right: 0;
                        top: -5px;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 768px) {
    .mobile-menu {
        display: none;
    }
}
</style>
