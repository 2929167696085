<template>
  <ul class="main-menu">
    <!-- Minified logo for scroll header -->
    <li class="menu-item show-if-sticky">
      <a
        v-bind="{ 'href': $i18n.locale === 'en_US' ? '/en/' : ($i18n.locale === 'de_AT' ? '/de-at/' : '/') }"
        title="Zur Startseite"
      >
        <img
          src="/typo3conf/ext/crefo_portal/Resources/Public/img/logo/without-claim/CR-Bildmarke-RGB.svg"
          width="26"
          height="34"
        >
      </a>
    </li>

    <!-- Main menu items -->
    <li
      class="menu-item has-submenu"
      v-for="(item, i) in mainMenu"
      :key="i"
      v-if="showSubmenu(item)"
    >
      <a
        :data-qa="item.dataQa"
        href="javascript:void(0);"
      >
        <span>{{ $t(item.translateKey) }}</span>
      </a>
      <div class="main-menu--slide">
        <span
          v-for="(subitem, n) in item.subitems"
          :key="n"
          v-if="subitem.show"
          class="submenu-item"
        >
          <span v-if="subitem.isRouterLink">
            <router-link
              v-bind="{ 'to': setRouterLinkByLanguage(subitem)}"
              :data-qa="subitem.dataQa"
            >
              <span>{{ $t(subitem.translateKey) }}</span>
            </router-link>
          </span>
          <span v-else>
            <a
              v-bind="{ 'href': setHrefLinkByLanguagen(subitem) }"
              :target="hrefTarget(subitem)"
              :data-qa="subitem.dataQa"
            >
              <span>{{ $t(subitem.translateKey) }}</span>
            </a>
          </span>
        </span>
      </div>
    </li>
    <li v-else-if="!showSubmenu(item) && item.show">
        <a
            :data-qa="item.dataQa"
            href="javascript:void(0);"
        >
            <span
                v-if="item.isRouterLink"
            >
                <router-link
                    v-bind="{ 'to': setRouterLinkByLanguage(item)}"
                    :data-qa="item.dataQa"
                >
                    <span>{{ $t(item.translateKey) }}</span>
                </router-link>
            </span>
            <span v-else>
                <a
                    v-bind="{ 'href': setHrefLinkByLanguagen(item) }"
                    :target="hrefTarget(item)"
                    :data-qa="item.dataQa"
                >
                    <span>{{ $t(item.translateKey) }}</span>
                </a>
            </span>
        </a>
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import { getUserManagementSelfcareUrl } from '@/keycloak-config'
import { standardInkassoSubitems, getCustomInkassoLinksForClz } from "@/components/navigation/partials/inkasso-sub-menu-items"

export default {
    name: 'MainMenu',
    data () {
        return {
            newSelfcareLink: '',
            newSelfcareLinkLoaded: false,
            inkassoSubitems: standardInkassoSubitems
        }
    },
    computed: {
        ...mapState([
            'featureModule'
        ]),
        setRouterLinkByLanguage() {
            return (subitem) => {

                if(this.$i18n.locale === 'en_US') {
                    return subitem.href.en
                } else if(this.$i18n.locale === 'de_AT') {
                    return subitem.href.at
                } else if(subitem.href.fr && this.$i18n.locale === 'fr_FR') {
                    return subitem.href.fr
                } else if(subitem.href.lu && this.$i18n.locale === 'fr_LU') {
                    return subitem.href.lu
                } else {
                    return subitem.href.de
                }
            }
            // Backup falls Probleme auftreten
            // $i18n.locale === 'en_US' ? subitem.href.en : (subitem.href.at && $i18n.locale === 'de_AT' ? subitem.href.at : subitem.href.de)
        },
        setHrefLinkByLanguagen() {
            return (subitem) => {
                if(this.$i18n.locale === 'en_US') {
                    return subitem.href.en
                } else if(this.$i18n.locale === 'de_AT') {
                    return (subitem.href.at ? subitem.href.at : '/de-at' + subitem.href.de)
                } else if(subitem.href.fr && this.$i18n.locale === 'fr_FR') {
                    return subitem.href.fr
                } else if(subitem.href.lu && this.$i18n.locale === 'fr_LU') {
                    return subitem.href.lu
                } else {
                    return subitem.href.de
                }
            }
            // Backup falls Probleme auftreten
            //$i18n.locale === 'en_US' ? subitem.href.en : ($i18n.locale === 'de_AT' ? (subitem.href.at ? subitem.href.at : '/de-at' + subitem.href.de) : subitem.href.de)
        },
        mainMenu () {
            return !this.isMitgliedsbenutzer ? this.mainMenuRegusers : this.mainMenuMembers
        },
        mainMenuMembers () {
            const getMahnwesenLink = () => {
                let subdomain = location.hostname.replace('cp.', '.').replace('meine.creditreform.de', '')
                if (subdomain === '') {
                    subdomain = 'www.'
                } else if (subdomain === 'sunzinet.') {
                    subdomain = 'sedev.'
                }
                return `https://${subdomain}creditreform-mahnwesen.de/de`
            }
            return [
                {
                    translateKey: 'mainmenu.businessreports.title',
                    dataQa: 'tabCategory-6',
                    subitems: [
                        {
                            translateKey: 'mainmenu.businessreports.subitem.reportb2b',
                            dataQa: 'submenuItem-27',
                            show: true,
                            isRouterLink: false,
                            href: {
                                de: '/bonitaetsauskunft/bonitaet-einer-firma-pruefen/',
                                en: '/en/business-reports/business-reports/',
                                fr: '/fr/solvabilite/verifier-la-solvabilite-dune-entreprise/',
                                lu: '/fr/solvabilite/verifier-la-solvabilite-dune-entreprise/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.businessreports.subitem.reportb2c',
                            dataQa: 'submenuItem-201',
                            show: true,
                            isRouterLink: false,
                            href: {
                                de: '/bonitaetsauskunft/bonitaet-einer-privatperson-pruefen/',
                                at: '/de-at/bonitaetsauskunft/bonitaet-einer-privatperson-aus-deutschland-pruefen/',
                                en: '/en/business-reports/reports-on-private-persons/',
                                fr: '/fr/solvabilite/verifier-la-solvabilite-dun-particulier/',
                                lu: '/fr/solvabilite/verifier-la-solvabilite-dun-particulier/'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.debtcollection.title',
                    dataQa: 'tabCategory-7',
                    subitems: this.inkassoSubitems
                },
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey: 'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-270',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/meine-bonitaet',
                                en: '/portal/meine-bonitaet',
                                at: '/portal/meine-bonitaet/',
                                fr: '/portal/meine-bonitaet/',
                                lu: '/portal/meine-bonitaet/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'submenuItem-ubo',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/ubo/',
                                en: '/portal/ubo/',
                                at: '/portal/ubo/',
                                fr: '/portal/ubo/',
                                lu: '/portal/ubo/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'submenuItem-myesg',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/myesg/',
                                en: '/portal/myesg/',
                                at: '/portal/myesg/',
                                fr: '/portal/myesg/',
                                lu: '/portal/myesg/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.companydata',
                            dataQa: 'submenuItem-companydata',
                            show: this.userHasRole('CR-CP.UNTERNEHMENSDATEN'),
                            isRouterLink: false,
                            href: {
                                de: '/mein-profil/?area=unternehmensdaten',
                                en: '/en/my-profile/?area=business%20data',
                                fr: '/fr/mon-profil/?area=business%20data',
                                lu: '/fr-lu/mon-profil/?area=business%20data'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.userdata',
                            dataQa: 'submenuItem-userdata',
                            show: this.userHasRole('CR-CP.MITGLIEDSDATEN'),
                            isRouterLink: false,
                            href: {
                                de: '/mein-profil/?area=mitgliedsdaten',
                                en: '/en/my-profile/?area=membership%20data',
                                fr: '/fr/mon-profil/?area=membership%20data',
                                lu: '/fr-lu/mon-profil/?area=membership%20data'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.mahnwesen',
                            dataQa: 'submenuItem-mahnwesen',
                            show: this.isNotAustrian && this.isNotLuxemburg,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: {
                                de: getMahnwesenLink(),
                                en: getMahnwesenLink()
                            }
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive && this.isNotLuxemburg,
                            isRouterLink: true,
                            href: {
                                de: '/portal/drd',
                                en: '/portal/drd',
                                fr: '/portal/drd'
                            }
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.crefoaddress',
                            dataQa: 'submenuItem-crefoaddress',
                            show: true,
                            isRouterLink: false,
                            isExternalLink: true,
                            href: {
                                at: 'https://crefoaddress.de',
                                de: 'https://crefoaddress.de',
                                en: 'https://crefoaddress.de',
                                fr: 'https://crefoaddress.de',
                                lu: 'https://crefoaddress.de'
                            }
                        },
                        {
                            translateKey: 'mainmenu.services.subitem.magazine',
                            dataQa: 'submenuItem-magazine',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/creditreform-magazin',
                                en: '/portal/creditreform-magazin',
                                fr: '/portal/creditreform-magazin',
                                lu: '/portal/creditreform-magazin'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.auswertungen.title',
                    dataQa: 'tabCategory-auswertungen',
                    isRouterLink: false,
                    show: this.userHasCrMemberuserid() && !this.isActFor,
                    subitems: [],
                    href: {
                        at: '/portal/auskunftsexport',
                        de: '/portal/auskunftsexport',
                        en: '/portal/auskunftsexport',
                        fr: '/portal/auskunftsexport',
                        lu: '/portal/auskunftsexport'
                    }
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: false,
                    subitems:[],
                    href: {
                        at: '/portal/downloadbereich',
                        de: '/portal/downloadbereich',
                        en: '/portal/downloadbereich',
                        fr: '/portal/downloadbereich',
                        lu: '/portal/downloadbereich'
                    }
                }
            ]
        },
        mainMenuRegusers () {
            return [
                {
                    translateKey: 'mainmenu.mycompany.title',
                    dataQa: 'tabCategory-mycompany',
                    subitems: [
                        {
                            translateKey: 'mainmenu.mycompany.subitem.mycompany',
                            dataQa: 'tabCategory-270',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/meine-bonitaet',
                                en: '/portal/meine-bonitaet',
                                at: '/portal/meine-bonitaet/',
                                fr: '/portal/meine-bonitaet/',
                                lu: '/portal/meine-bonitaet/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.ubo',
                            dataQa: 'submenuItem-ubo',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/ubo/',
                                en: '/portal/ubo/',
                                at: '/portal/ubo/',
                                fr: '/portal/ubo/',
                                lu: '/portal/ubo/'
                            }
                        },
                        {
                            translateKey: 'mainmenu.mycompany.subitem.myEsg',
                            dataQa: 'submenuItem-myesg',
                            show: true,
                            isRouterLink: true,
                            href: {
                                de: '/portal/myesg/',
                                en: '/portal/myesg/',
                                at: '/portal/myesg/',
                                fr: '/portal/myesg/',
                                lu: '/portal/myesg/'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.services.title',
                    dataQa: 'tabCategory-services',
                    subitems: [
                        {
                            translateKey: 'mainmenu.services.subitem.drd',
                            dataQa: 'submenuItem-drd',
                            show: this.featureDrdIsActive,
                            isRouterLink: true,
                            href: {
                                de: '/portal/drd',
                                en: '/portal/drd'
                            }
                        }
                    ]
                },
                {
                    translateKey: 'mainmenu.downloadbereich.title',
                    dataQa: 'tabCategory-downloadbereich',
                    show: !this.isActFor,
                    isRouterLink: false,
                    subitems:[],
                    href: {
                        at: '/portal/downloadbereich',
                        de: '/portal/downloadbereich',
                        en: '/portal/downloadbereich',
                        fr: '/portal/downloadbereich',
                        lu: '/portal/downloadbereich'
                    }
                }
            ]
        },
        featureDrdIsActive () {
            return this.$store.state.featureModule.drd.active
        },
        isNotAustrian () {
            return this.$i18n.locale !== 'de_AT'
        },
        isNotLuxemburg () {
            return this.$i18n.locale !== 'fr_LU'
        },
        isMitgliedsbenutzer () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        isActFor () {
            return this.$store.state.userModule.user.user.actForMemberId
        }
    },
    methods: {
        showSubmenu (item) {
            if (!item.subitems.length) {
                return false
            }

            let i
            for (i = 0; i < item.subitems.length; i++) {
                if (item.subitems[i].show) {
                    return true
                }
            }

            return false
        },
        userHasRole (roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(roleName)
        },
        userHasOneOfRoles (roleNames) {
            return this.$store.state.userModule.user.user.user_roles.some(role => roleNames.includes(role))
        },
        userHasCrMemberuserid () {
            return this.$store.state.userModule.user.user.crMemberuserid ? true : false
        },
        hrefTarget (el) {
            return el.isExternalLink ? '_blank' : ''
        },
        async getNewSelfcareLink () {
            let res = await getUserManagementSelfcareUrl()
            this.newSelfcareLinkLoaded = true
            this.newSelfcareLink = res
        }
    },
    mounted () {
        if (this.$store.state.userModule.user.authenticated) {
            this.getNewSelfcareLink()
        } else {
            this.$store.watch((state) => state.userModule.user.authenticated, (newProps, _oldProps) => {
                if (newProps) {
                    this.getNewSelfcareLink()
                }
            })
        }

        const userClz = this.$store.state.userModule.user.user.clz
        if (userClz !== null) {
            getCustomInkassoLinksForClz(userClz).then((subitems) => { this.inkassoSubitems = subitems })
        } else {
            this.$store.watch((state) => state.userModule.user.user.clz, (newClz, _oldClz) => {
                if (newClz) {
                    getCustomInkassoLinksForClz(newClz).then((subitems) => { this.inkassoSubitems = subitems })
                }
            })
        }
    }
}
</script>

<style scoped lang="less">
.header-nav ul.main-menu a {
    &.router-link-exact-active {
        color: #004884;
    }
}
.header-nav .main-menu .has-submenu .main-menu--slide a {
    width: 100%;
}
.header-nav .main-menu .has-submenu.active .main-menu--slide {
    width: 320px;
}
.header-nav .main-menu .has-submenu.active .main-menu--slide > span:not(:last-child) {
    margin-bottom: 15px;
    display: block;
}
</style>
