import * as api from '../../services/index.js'

const STORAGE_TTL_FEATURE_FLAGS = 1 * 3600 * 1000 // 1 hour

const STORAGE_KEY_DRD = 'featureflag_drd'
const STORAGE_KEY_MONOREPO = 'featureflags_monorepo'

/**
 * Get status of feature toggle DRD.
 *
 * @param {function} commit
 */
 export async function getFeatureDrd({ commit }, storage) {
    commit('GET_FEATURE_DRD')

    let features = {}
    let hasFeatureFlags = storage.has(STORAGE_KEY_DRD)
    let featureIsActive = false

    if (hasFeatureFlags) {
        features = storage.get(STORAGE_KEY_DRD)
    }

    if (hasFeatureFlags && features !== null) {
        featureIsActive = STORAGE_KEY_DRD in features && !!features[STORAGE_KEY_DRD]
        commit('RECEIVE_FEATURE_DRD', featureIsActive)
    } else {
        try {
            const response = await api.getFeatureDrd()
            if (response.data && response.data.active === true) {
                featureIsActive = true
                commit('RECEIVE_FEATURE_DRD', featureIsActive)
            }
        } catch (error) {
            featureIsActive = null
            commit('ERROR_FEATURE_DRD', error)
        }

        if (features === null) {
            features = {}
        }

        features[STORAGE_KEY_DRD] = featureIsActive !== null ? featureIsActive : false
        storage.set(STORAGE_KEY_DRD, features, STORAGE_TTL_FEATURE_FLAGS)
    }
}

/**
 * Get status of all monorepo features.
 *
 * @param {function} commit
 */
export async function getFeaturesMonorepo({ commit }, storage) {
    commit('GET_FEATURES_MONOREPO')

    let featureFlags = {}
    let hasFeatureFlags = storage.has(STORAGE_KEY_MONOREPO)
    if (hasFeatureFlags) {
        featureFlags = storage.get(STORAGE_KEY_MONOREPO)
    }

    if (hasFeatureFlags && typeof featureFlags === 'object' && featureFlags !== null) {
        commit('RECEIVE_FEATURES_MONOREPO', featureFlags)
    } else {
        let featureFlags = {}

        try {
            const response = await api.getFeaturesMonorepo()
            if (response?.data && typeof response.data === 'object') {
                commit('RECEIVE_FEATURES_MONOREPO', response.data)
                featureFlags = response.data
            } else {
                commit('ERROR_FEATURES_MONOREPO', null)
            }
        } catch (error) {
            commit('ERROR_FEATURES_MONOREPO', error)
        }

        storage.set(STORAGE_KEY_MONOREPO, featureFlags, STORAGE_TTL_FEATURE_FLAGS)
    }
}
