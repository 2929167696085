import DataReady from './DataReady'
import SeleniumHelper from './SeleniumHelper'

export default {

    install(Vue, options) {
        // Do not use this plugin in production (for better performance)
        if (typeof window?.location?.href === 'string') {
            if (window.location.href.indexOf('https://meine.creditreform.de') === 0) {
                return
            }
            if (window.location.href.indexOf('http://meine.creditreform.de') === 0) {
                return
            }
        }

        // Do not use this plugin in (jest) test mode
        if (process && process.env && process.env.NODE_ENV === 'test') {
            return
        }

        DataReady.init()
        SeleniumHelper.init()

        // Add event listeners for non shared vue components
        window.addEventListener('data-ready-before-mount', onEventBeforeMount, false)
        window.addEventListener('data-ready-mounted', onEventMounted, false)

        Vue.mixin({
            beforeMount: function () {
                ++DataReady.lifeCycleCounter
                DataReady.checkIsReady()
            },
            mounted: function () {
                --DataReady.lifeCycleCounter
                DataReady.checkIsReady()
            }
        })
    }

}

function onEventBeforeMount (event) {
    ++DataReady.lifeCycleCounter
    DataReady.checkIsReady()
}

function onEventMounted (event) {
    --DataReady.lifeCycleCounter
    DataReady.checkIsReady()
}
