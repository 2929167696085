import './set-public-path'
import Vue from 'vue'
import singleSpaVue from 'single-spa-vue'

import App from './App.vue'

import router from './router'
import store from './store'
import i18n from './i18n'

import DataReady from './plugins/data-ready'
import CrefoUI from './plugins/crefo-ui'
import WebtrekkSmartpixelVue from '@webtrekk-smart-pixel/vue'
import { MockWebtrekkForUserCentrics } from '@/helper/webtrekk'

// Require https - Otherwise Keycloak may complain
if (window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http://', 'https://')
}

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(CrefoUI)
Vue.use(DataReady)

const webtrekkConfig = {
    trackId: window.location.hostname === 'meine.creditreform.de' ? '195357560818821' : '465631094018133',
    trackDomain: 'webmet.creditreform.de',
    domain: window.location.hostname,
    cookie: '3',
    activateLinkTracking: true,
    activateAutoTracking: true
}

Vue.use(WebtrekkSmartpixelVue, webtrekkConfig)

MockWebtrekkForUserCentrics()

const vueLifecycles = singleSpaVue({
    Vue,
    appOptions: {
        render: h => h(App),
        router,
        store,
        i18n
    }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
