<template>
    <span class="sidebar-navigation__item_inner">
        <span
            class='sidebar-navigation__icon'
            :class='item.icon'
        >
        </span>
        <span class="sidebar-navigation__label">
            {{ $t(item.data.title) }}
        </span>
        <span
            v-if='item.data.title === "featurenav.inbox" && unreadMessagesCount > 0'
            class='sidebar-navigation__bubble'
        >
            <span data-qa="activityCounter">{{ unreadMessagesCount }}</span>
        </span>
        <span
            v-if='item.data.title === "featurenav.watchlist" && watchlistCount > 0'
            class='sidebar-navigation__bubble'
        >
            <span data-qa="watchlistCounter">{{ watchlistCount }}</span>
        </span>
    </span>
</template>

<script>
export default {
    name: "CrefoSidebarNavigationLinkInner",
    props: {
        item: {
            type: Object,
            default: {}
        },
        unreadMessagesCount: {
            type: Number,
            default: 0
        },
        watchlistCount: {
            type: Number,
            default: 0
        }
    },
    computed: {

    }
}
</script>

<style scoped>

.sidebar-navigation__item_inner {
    text-align: center;
}
.sidebar-navigation__icon {
    display: block;
    -ms-flex-item-align: center;
    align-self: center;
    font-size: 30px;
    margin-top: -15px;
}
.sidebar-navigation__icon.crefo-brand-icon::before,
.sidebar-navigation__icon.crefo-ui-icon::before {
    color: #fff;
}
.sidebar-navigation__icon.crefo-brand-icon::before,
.sidebar-navigation__icon.crefo-ui-icon::before {
    color: #fff;
}
.sidebar-navigation__label {
    display: block;
    position: absolute;
    left: 2px;
    bottom: 12px;
    width: calc(100% - 6px);
    text-align: center;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
}
.sidebar-navigation__bubble {
    background-color: #A763A4;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
    min-width: 24px;
    height: 24px;
    text-align: center;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: absolute;
    right: 8px;
    top: 8px;
    display: inline-block;
    line-height: 1;
}
.sidebar-navigation__bubble>span {
    line-height: 24px;
    padding: 0 5px;
    font-size: 15px;
}
</style>
