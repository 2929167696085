import storage from 'localstorage-with-ttl'

function deleteCookieByName(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
}

function clearAllStorages () {
    clearSessionStorage() 
    clearLocalStorage()
    clearStorage()
    clearCookies()
}

function clearLocalStorage () {
    localStorage.removeItem("vue-token")
    localStorage.removeItem("vue-refresh-token")
}

function clearSessionStorage () {
    sessionStorage.removeItem("actForMemberId")
    sessionStorage.removeItem("actForMemberName")
    sessionStorage.removeItem("originalMemberId")
    sessionStorage.removeItem("reportAct4SbNumber")
    sessionStorage.removeItem('skipActForMemberHeader')
    sessionStorage.removeItem('/cbra/userPreferences')
}

function clearStorage () {
    storage.remove('uid')
    storage.remove('features')
    storage.remove('featureflags_monorepo')
    storage.remove('featureflag_drd')
    storage.remove('featureflag_kp')
}

function clearCookies() {
    deleteCookieByName('TRANSACTION_LIST_COOKIE')
    deleteCookieByName('WATCHLIST_LIST_COOKIE')
    deleteCookieByName('INFOBOX_CLOSED')
    deleteCookieByName('ONBOARDING_SIZE_STATE')
    deleteCookieByName('WKV_OVERLAY_SHOWN')
    deleteCookieByName('SESSION_SETTINGS')
}

export { clearAllStorages }
