<template>
    <ul class="service-menu">
        <li class="menu-item" v-for="(item, index) in serviceMenu" :key="index" v-if="item.show">
            <a
                v-bind="{'href': setHrefLinkByLanguage(item) }"
                :data-qa="item.dataQa"
            >
                {{ $t(item.translateKey) }}
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'ServiceMenu',
    computed: {
        serviceMenu: function () {
            return [
                {
                    translateKey: 'servicemenu.about',
                    dataQa: 'navbar__item-11',
                    show: true,
                    href: {
                        de: '/ueber-creditreform/',
                        at: '/de-at/ueber-creditreform/',
                        en: '/en/about-creditreform/',
                        fr: '/fr/a-propos-de-creditreform/',
                        lu: '/fr-lu/a-propos-de-creditreform/'
                    }
                },
                {
                    translateKey: 'servicemenu.contact',
                    dataQa: 'navbar__item-13',
                    show: this.$store.state.userModule.user.authenticated && this.$store.state.userModule.user.user.isMitgliedsbenutzer,
                    href: {
                        de: '/kontakt/',
                        at: '/de-at/kontakt/',
                        en: '/en/contact/',
                        fr: '/fr/contact/',
                        lu: '/fr-lu/contact'
                    }
                }
            ]
        }
    },
    methods: {
        setHrefLinkByLanguage(subitem) {
            if(this.$i18n.locale === 'en_US') {
                return subitem.href.en
            } else if(subitem.href.at && this.$i18n.locale === 'de_AT') {
                return subitem.href.at
            } else if(subitem.href.fr && this.$i18n.locale === 'fr_FR') {
                return subitem.href.fr
            } else if(subitem.href.lu && this.$i18n.locale === 'fr_LU') {
                return subitem.href.lu
            } else {
                return subitem.href.de
            }
        }
    }
}
</script>

<style scoped lang="less">
.flex-header {
    .service-menu {
        top: 0;
        transform: none;
        position: relative;
    }
}
@media only screen and (min-width: 768px) {
  .header-nav.has-mainmenu ul.service-menu,
  .header-nav.has-servicemenu ul.service-menu {
    right: 15px;
  }
}
</style>
