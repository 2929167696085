import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// const BASE_PATH_MICROFRONTEND_CONTAINER = '/portal'

const routes = []

const router = new VueRouter({
    mode: 'history',
    base: '/', // this is crucial in order to rewrite links to the single-spa container!
    routes
})

const logoutConfigurations = [
    { match: '/portal/meine-bonitaet', logoutUrl: '/portal/meine-bonitaet' },
    { match: '/portal/ubo', logoutUrl: '/portal/ubo' },
    { match: '/portal/lksg', logoutUrl: '/portal/lksg' },
    { match: '/portal/myesg', logoutUrl: '/portal/myesg' }
]

router.getLogoutUrl = () => {
    const origin = `${location.protocol}//${location.host}`
    const logoutConfig = logoutConfigurations.find(config => location.href.indexOf(config.match) !== -1)
    if (logoutConfig) {
        return `${origin}${logoutConfig.logoutUrl}`
    } else {
        return origin
    }
}

export default router
