import * as api from '../../services/index.js'

export function authenticated ({ commit }, data) {
    commit('USER_AUTHENTICATED', data)
}

export function tokenUpdated ({ commit }, data) {
    commit('USER_TOKEN_UPDATED', data)
}

export function setActForMember ({ commit }, data) {
    commit('SET_ACT_FOR_MEMBER', data)
}

export function setMemberNameAndId({ commit }, data) {
    commit('SET_MEMBER_NAME_AND_ID', data)
}

export function setLanguage ({ commit }, data) {
    const validLanguages = [
        'de_DE',
        'de_AT',
        'en_US'
    ]
    if (data !== null && data.length === '5' && validLanguages.includes(data)) {
        commit('SET_LANGUAGE', data)
    }
}

export async function getUserdata ({ commit }) {
    try {
        const response = await api.getCbraUserdata()
        if (response.status && response.status === 200 && response.data) {
            commit('RECEIVE_USER_DATA_SUCCESS', response.data)
        }
    } catch (error) {
        commit('RECEIVE_USER_DATA_ERROR', error)
    }
}

export async function getMemberdata ({ commit }) {
    try {
        const response = await api.getCbraUserMemberdata()
        if (response.status && response.status === 200 && response.data) {
            commit('RECEIVE_MEMBER_DATA_SUCCESS', response.data)
        }
    } catch (error) {
        commit('RECEIVE_MEMBER_DATA_ERROR', error)
    }
}

export async function getMemberStructure ({ commit }) {
    try {
        const response = await api.getMemberStructure()
        if (response.status && response.status === 200 && response.data) {
            commit('RECEIVE_MEMBER_STRUCTURE_SUCCESS', response.data)
        }
    } catch (error) {
        commit('RECEIVE_MEMBER_STRUCTURE_ERROR', error)
    }
}
