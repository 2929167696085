export function CLOSE_LANG_SWITCH (state) {
    state.nav.langSwitchOpen = false
}

export function OPEN_LANG_SWITCH (state) {
    state.nav.langSwitchOpen = true
    state.nav.searchOpen = false
    state.nav.profileMenuOpen = false
    state.nav.mobileOpen = false
    state.nav.memberstructureNavigationOpen = false
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function CLOSE_PROFILE_MENU (state) {
    state.nav.profileMenuOpen = false
}

export function OPEN_PROFILE_MENU (state) {
    state.nav.profileMenuOpen = true
    state.nav.searchOpen = false
    state.nav.langSwitchOpen = false
    state.nav.mobileOpen = false
    state.nav.memberstructureNavigationOpen = false
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function CLOSE_SEARCH (state) {
    state.nav.searchOpen = false
}

export function OPEN_SEARCH (state) {
    state.nav.searchOpen = true
    state.nav.profileMenuOpen = false
    state.nav.langSwitchOpen = false
    state.nav.mobileOpen = false
    state.nav.memberstructureNavigationOpen = false
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function CLOSE_MEMBER_STRUCTURE_NAVIGATION (state) {
    state.nav.memberstructureNavigationOpen = false
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function OPEN_MEMBER_STRUCTURE_NAVIGATION (state) {
    state.nav.searchOpen = false
    state.nav.profileMenuOpen = false
    state.nav.langSwitchOpen = false
    state.nav.mobileOpen = false
    state.nav.memberstructureNavigationOpen = true
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function CLOSE_MOBILE_NAVIGATION (state) {
    state.nav.mobileOpen = false
}

export function OPEN_MOBILE_NAVIGATION (state) {
    state.nav.searchOpen = false
    state.nav.profileMenuOpen = false
    state.nav.langSwitchOpen = false
    state.nav.mobileOpen = true
    state.nav.memberstructureNavigationOpen = false
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function TOGGLE_MOBILE_NAVIGATION (state, data) {
    state.nav.mobileOpen = data
}

export function CLOSE_ALL_NAV_MENUS (state) {
    state.nav.langSwitchOpen = false
    state.nav.profileMenuOpen = false
    state.nav.searchOpen = false
    state.nav.mobileOpen = false
    state.nav.memberstructureNavigationOpen = false
    state.nav.memberstructureNavigationOptionsSearch = ''
}

export function SET_WATCHLIST_COUNT (state, data) {
    state.nav.watchlistCount = data
}

export function SET_UNREAD_MESSAGES_COUNT (state, data) {
    state.nav.unreadMessagesCount = data
}
