import { useNewInkassoFrontend } from "@/services"
import store from '@/store'

export const standardInkassoSubitems = [
    {
        translateKey: 'mainmenu.debtcollection.subitem.withcrefo',
        dataQa: 'submenuItem-116',
        show: true,
        isRouterLink: false,
        href: {
            de: '/inkasso/inkasso-mit-creditreform/',
            en: '/en/debt-collection/debt-collection-with-creditreform/',
            fr: '/fr/recouvrement/recouvrement-avec-creditreform/',
            lu: '/fr-lu/recouvrement/recouvrement-avec-creditreform/'
        }
    },
    {
        translateKey: 'mainmenu.debtcollection.subitem.viaupload',
        dataQa: 'submenuItem-109',
        show: true,
        isRouterLink: false,
        href: {
            de: '/inkasso/inkassoauftrag-per-upload/',
            en: '/en/debt-collection/upload-for-debt-collection/',
            fr: '/fr/recouvrement/recouvrement-par-telechargement/',
            lu: '/fr-lu/recouvrement/recouvrement-par-telechargement/'
        }
    },
    {
        translateKey: 'mainmenu.debtcollection.subitem.viaform',
        dataQa: 'submenuItem-64',
        show: true,
        isRouterLink: false,
        href: {
            de: '/inkasso/inkassoauftrag-per-formular/',
            en: '/en/debt-collection/fill-out-forms-for-debt-collection/',
            fr: '/fr/recouvrement/recouvrement-par-formulaire/',
            lu: '/fr-lu/recouvrement/recouvrement-par-formulaire/'
        }
    }
]

const $env = process.env.VARS

const getOptionalGlaeubigerPortalMappingSubitems = (clz) => {
    if (!$env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING || !$env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING.length) {
        console.log('[Inkasso Menü] CLZ Mapping nicht definiert')
        return undefined
    }

    let mapping = {}
    try {
        mapping = JSON.parse(atob($env.GLAUBIGER_PORTAL_LINK__CLZ_MAPPING))
        console.log('[Inkasso Menü] CLZ Mapping Gläubigerportal Inkasso Links: ', mapping)
    } catch (e) {
        console.log('[Inkasso Menü] CLZ Mapping Gläubigerportal ungültig')
        return undefined
    }

    if (mapping[clz] && mapping[clz].length) {
        return [
            {
                translateKey: 'mainmenu.debtcollection.subitem.glaeubigerPortalForClz',
                dataQa: 'submenuItem-666_' + clz,
                show: true,
                isExternalLink: true,
                isRouterLink: false,
                href: {
                    de: mapping[clz],
                    en: mapping[clz],
                    fr: mapping[clz],
                    lu: mapping[clz]
                }
            }
        ]
    }
    console.log('[Inkasso Menü] CLZ Mapping Gläubigerportal nicht für dies clz')
    return undefined
}

const userHasRole = (roleName) => {
    return store.state.userModule.user.user.user_roles.includes(roleName)
}

const getNewInkassoFrontendLinks = (clz) => {
    return [
        {
            translateKey: 'mainmenu.debtcollection.subitem.withcrefo',
            dataQa: 'submenuItem-ikaros_start_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: {
                at: '/portal/inkasso',
                de: '/portal/inkasso',
                en: '/portal/inkasso',
                fr: '/portal/inkasso',
                lu: '/portal/inkasso'
            }
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.inkassoFiles',
            dataQa: 'submenuItem-ikaros_files_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: {
                at: '/portal/inkasso/uebersicht',
                de: '/portal/inkasso/uebersicht',
                en: '/portal/inkasso/uebersicht',
                fr: '/portal/inkasso/uebersicht',
                lu: '/portal/inkasso/uebersicht'
            }
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.viascan',
            dataQa: 'submenuItem-ikaros_scan_' + clz,
            show: userHasRole('CR-CP.INKASSO-SCAN'),
            isExternalLink: false,
            isRouterLink: true,
            href: {
                at: '/portal/inkasso/inkassoauftrag-per-datenerkennung',
                de: '/portal/inkasso/inkassoauftrag-per-datenerkennung',
                en: '/portal/inkasso/inkassoauftrag-per-datenerkennung',
                fr: '/portal/inkasso/inkassoauftrag-per-datenerkennung',
                lu: '/portal/inkasso/inkassoauftrag-per-datenerkennung'
            }
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.viaupload',
            dataQa: 'submenuItem-ikaros_upload_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: {
                at: '/portal/inkasso/inkassoauftrag-per-upload',
                de: '/portal/inkasso/inkassoauftrag-per-upload',
                en: '/portal/inkasso/inkassoauftrag-per-upload',
                fr: '/portal/inkasso/inkassoauftrag-per-upload',
                lu: '/portal/inkasso/inkassoauftrag-per-upload'
            }
        },
        {
            translateKey: 'mainmenu.debtcollection.subitem.viaform',
            dataQa: 'submenuItem-ikaros_formular_' + clz,
            show: true,
            isExternalLink: false,
            isRouterLink: true,
            href: {
                at: '/portal/inkasso/inkassoauftrag-per-formular',
                de: '/portal/inkasso/inkassoauftrag-per-formular',
                en: '/portal/inkasso/inkassoauftrag-per-formular',
                fr: '/portal/inkasso/inkassoauftrag-per-formular',
                lu: '/portal/inkasso/inkassoauftrag-per-formular'
            }
        }
    ]
}

/** Returns Promise with individual sub menu items for the main/mobile menu.
 *
 * @param userClz of current user
 * @returns "Promise<[ <sub menu items> ]>"
 */
export const getCustomInkassoLinksForClz =  (userClz) => {

    console.log('[Inkasso Menü] User CLZ: ', userClz)
    console.log('[Inkasso Menü] Env vars: ', $env)

    if (!userClz || typeof userClz === 'undefined') {
        // Wenn Umgebungsvariablen nicht geladen oder die Benutzer CLZ nicht ermittelt werden kann,
        // wird das CTI Inkasso als Standard im Menü angezeigt
        return Promise.resolve(standardInkassoSubitems)
    }

    const glaubigerPortalMapping = getOptionalGlaeubigerPortalMappingSubitems(userClz)
    if (glaubigerPortalMapping) {
        // wenn spezial, dann immer spezial
        return Promise.resolve(glaubigerPortalMapping)
    }

    if ($env.FEATURE_IKAROS_ACTIVE && $env.FEATURE_IKAROS_ACTIVE !== 'false') {
        return useNewInkassoFrontend().then((newInkasso) => {
            if (newInkasso) {
                return getNewInkassoFrontendLinks(userClz)
            }
            return standardInkassoSubitems
        })
    }
    return Promise.resolve(standardInkassoSubitems)
}
