/**
 * Holds the states of feature toggles.
 *
 * @type FeatureStates
 */
export default {
    drd: {
        fetching: false,
        fetched: false,
        active: false,
        error: false,
        errorData: null
    },
    monorepo: {
        fetching: false,
        fetched: false,
        active: {
            askNotificationActive: false,
            portfolioViewActive: false,
            submitCollectionFileInfoActive: false,
            wkvActive: false,
            wkvRuVActive: false
        },
        error: false,
        errorData: null
    }
}

/**
 * TypeDef FeatureStates
 *
 * @typedef {Object} FeatureStates
 * @property {FeatureState} drd
 * @property {FeatureStateMonorepo} monorepo
 */

/**
 * TypeDef FeatureState
 *
 * @typedef {Object} FeatureState
 * @property {boolean} fetching - Feature is currently beeing fetched
 * @property {boolean} fetched - Feature was fetched successfully
 * @property {boolean} active - If feature is active or not
 * @property {boolean} error - True, if feature status could not be featched
 * @property {?FeatureError} errorData - Axios error, while fetching feature
 */

/**
 * TypeDef FeatureStateMonorepo
 *
 * @typedef {Object} FeatureStateMonorepo
 * @property {boolean} fetching - Feature is currently beeing fetched
 * @property {boolean} fetched - Feature was fetched successfully
 * @property {Object} active
 * @property {boolean} active.askNotificationActive
 * @property {boolean} active.portfolioViewActive
 * @property {boolean} active.submitCollectionFileInfoActive
 * @property {boolean} active.wkvActive
 * @property {boolean} active.wkvRuVActive
 * @property {boolean} error - True, if feature status could not be featched
 * @property {?FeatureError} errorData - Axios error, while fetching feature
 */

/**
 * TypeDef FeatureError
 *
 * @typedef {Object} FeatureError
 * @property {Object} response
 * @property {*} response.data
 * @property {number} response.status
 * @property {string} response.statusText
 * @property {Object} response.headers
 * @property {Object} request
 * @property {Object} config
 */
