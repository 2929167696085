import * as api from "../../services";

export function closeLangSwitch ({ commit }) {
    commit('CLOSE_LANG_SWITCH')
}

export function openLangSwitch ({ commit }) {
    commit('OPEN_LANG_SWITCH')
}

export function closeProfileMenu ({ commit }) {
    commit('CLOSE_PROFILE_MENU')
}

export function openProfileMenu ({ commit }) {
    commit('OPEN_PROFILE_MENU')
}

export function closeSearch ({ commit }) {
    commit('CLOSE_SEARCH')
}

export function openSearch ({ commit }) {
    commit('OPEN_SEARCH')
}

export function closeMemberStructureNavigation ({ commit }) {
    commit('CLOSE_MEMBER_STRUCTURE_NAVIGATION')
}

export function openMemberStructureNavigation ({ commit }) {
    commit('OPEN_MEMBER_STRUCTURE_NAVIGATION')
}

export function closeMobile ({ commit }) {
    commit('CLOSE_MOBILE_NAVIGATION')
}

export function openMobile ({ commit }) {
    commit('OPEN_MOBILE_NAVIGATION')
}

export function toggleMobileNavigation ({ commit }, data) {
    commit('TOGGLE_MOBILE_NAVIGATION', data)
}

export function closeAllNavMenus ({ commit }) {
    commit('CLOSE_ALL_NAV_MENUS')
}

export function setWatchlistCount({ commit }, data) {
    api.getWatchlistCount()
        .then((data) => {
            commit('SET_WATCHLIST_COUNT', data.data.listSize)
        })
        .catch((err) => {
            commit('SET_WATCHLIST_COUNT', 0)
            console.log('Error: ', err)
        })
}

export function setUnreadMessagesCount({ commit, rootState }) {
    api.getUnreadMessagesCount(rootState.userModule.user.user.membernumber)
    .then((data) => {
        commit('SET_UNREAD_MESSAGES_COUNT', data.data)
    })
    .catch((err) => {
        commit('SET_UNREAD_MESSAGES_COUNT', 0)
        console.log('Error: ', err)
    })
}
