import { dispatchEvent } from '@/helper/polyfill'

export default {
    isReady: true,
    isReadyDelayTimer: null,
    lifeCycleCounter: 0,
    globalAjaxCounter: 0,

    init () {
        this.markAs(false)
        this.extendXMLHttpRequest()
    },

    checkIsReady () {
        if (this.lifeCycleCounter <= 0 && this.globalAjaxCounter <= 0) {
            this.handleIsReady()
        } else {
            this.markAs(false)
        }
    },

    handleIsReady (withoutDelay = false) {
        this.lifeCycleCounter = 0

        if (withoutDelay) {
            this.markAs(true)
        } else {
            if (this.isReadyDelayTimer !== null) {
                window.clearTimeout(this.isReadyDelayTimer)
            }
            this.isReadyDelayTimer = window.setTimeout(() => {
                this.isReadyDelayTimer = null
                this.handleIsReady(true)
            }, 15000) 
        }
    },

    markAs (ready) {
        if (ready && !this.isReady) {
            this.isReady = true
            document.body.dataset.ready = 'true'
            dispatchEvent('dataReady')
        } else if (!ready && this.isReady) {
            this.isReady = false
            document.body.dataset.ready = 'false'
        }
    },

    isSelenium () {
        if (navigator?.userAgent?.toLowerCase().indexOf('headless') !== -1) {
            return true
        }

        if (localStorage?.getItem('selenium')) {
            return true
        }

        return false
    },

    extendXMLHttpRequest () {
        const _self = this

        const globalAjaxListener = {
            open: XMLHttpRequest.prototype.open,
            send: XMLHttpRequest.prototype.send
        }

        XMLHttpRequest.prototype.open = function () {
            this.ajaxMethod = arguments[0]
            this.ajaxUrl = arguments[1]
            globalAjaxListener.open.apply(this, arguments)
        }

        XMLHttpRequest.prototype.send = function () {
            if (this.ajaxUrl.indexOf('/sockjs-node/') !== -1) {
                globalAjaxListener.send.apply(this, arguments)
                return
            }

            _self.globalAjaxCounter++
            this.addEventListener('readystatechange', event => {
                if (this.readyState === 4) {
                    _self.globalAjaxCounter > 1 ? _self.globalAjaxCounter-- : _self.globalAjaxCounter = 0
                    _self.checkIsReady()
                    if (_self.isSelenium() && this.ajaxMethod) {
                        const now = new Date()
                        let nowTimeString = now.toLocaleTimeString()
                        nowTimeString += '.' + now.getMilliseconds()
                        let answer = ''
                        if (this.responseType || this.responseType === '') {
                            switch (this.responseType) {
                                case '':
                                case 'text':
                                    answer = this.responseText ? this.responseText.substr(0, 250).replace(/(?:\r\n|\r|\n)/g, ' ') : ''
                                    break
                                case 'json':
                                    try {
                                        answer = this.response ? JSON.stringify(this.response).substr(0, 250).replace(/(?:\r\n|\r|\n)/g, ' ') : ''
                                    } catch (e) {}
                                    break
                            }
                        }
                        console.log(`AJAX (${nowTimeString}): ${this.status} ${this.ajaxMethod} ${this.ajaxUrl} -> ${answer}`)
                    }
                }
                return true
            })
            globalAjaxListener.send.apply(this, arguments)
            _self.checkIsReady()
        }
    }
}
