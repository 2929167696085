export const MockWebtrekkForUserCentrics = () => {
    let cmpSettingsInitialized = false
    let isAppendingWebtrekkData = false
    let checkWtSmartExists = setInterval(function () {
        if (window.wtSmart) {
            clearInterval(checkWtSmartExists)

            /**
             * True/False if consent is given.
             *
             * @param {object?} cmpSettings
             * @return {boolean}
             */
            const hasConsentForWebtrekk = function (cmpSettings = null) {
                if (cmpSettings && cmpSettings.services && cmpSettings.services.length) {
                    let wtConsent = cmpSettings.services.find(service => service.id === 'Ouzxu26Rv')
                    if (!wtConsent) {
                        console.log('[WebtrekkVue] ServiceId for Webtrekk not found. Service.id correct?')
                    }
                    return wtConsent?.status === true
                }
                return false
            }

            /**
             * True/False or a resolved/rejected Promise.
             *
             * @param {number} [maxWaitingTime]
             * @return {Promise|boolean}
             */
            const waitForHasConsentForWebtrekk = function (maxWaitingTime = 60000) { // 60 Seconds
                function fetchCmpSettings () {
                    let cmpSettings = localStorage.getItem('uc_settings')
                    if (cmpSettings !== null && cmpSettings.length) {
                        try {
                            cmpSettings = JSON.parse(cmpSettings)
                        } catch (e) {}
                    }
                    return cmpSettings
                }

                const currentCmpSettings = fetchCmpSettings()
                if (cmpSettingsInitialized) {
                    console.dir(currentCmpSettings)
                    return hasConsentForWebtrekk(currentCmpSettings)
                } else if (hasConsentForWebtrekk(currentCmpSettings)) {
                    cmpSettingsInitialized = true
                    console.dir(currentCmpSettings)
                    return true
                } else {
                    return new Promise((resolve, reject) => {
                        function waitForCmpSettings (timeout = 250, timeElapsed = 0) {
                            const cmpSettings = fetchCmpSettings()
                            if (hasConsentForWebtrekk(cmpSettings)) {
                                cmpSettingsInitialized = true
                                console.dir(cmpSettings)
                                resolve()
                            } else if (timeElapsed >= maxWaitingTime) {
                                cmpSettingsInitialized = true
                                console.dir(cmpSettings)
                                reject()
                            } else {
                                if (timeElapsed + timeout > maxWaitingTime) {
                                    timeout = maxWaitingTime - timeElapsed
                                }
                                setTimeout(() => {
                                    waitForCmpSettings(timeout * 2, timeElapsed + timeout)
                                }, timeout)
                            }
                        }
                        waitForCmpSettings()
                    })
                }
            }

            const checkCookieAndTrack = function (fnName, args) {
                const consentIsGiven = waitForHasConsentForWebtrekk()
                if (consentIsGiven === true) {
                    console.log('[WebtrekkVue] User consent for Webtrekk tracking given')
                    appendWebtrekkDataAndSend(fnName, args)
                } else if (consentIsGiven === false) {
                    console.log('[WebtrekkVue] Intercepted Webtrekk tracking due to missing user consent')
                } else {
                    consentIsGiven
                        .then(() => {
                            console.log('[WebtrekkVue] User consent for Webtrekk tracking was given delayed')
                            appendWebtrekkDataAndSend(fnName, args)
                        })
                        .catch(() => {
                            console.log('[WebtrekkVue] Intercepted Webtrekk tracking due to missing user consent after 60 seconds')
                        })
                }
            }

            const appendWebtrekkSessionData = function () {
                let dataExisting = window.wtSmart.session.data.get()
                if (areAllTrackingParametersSet(dataExisting, [2, 3, 4]) === true) {
                    return
                }

                const dataAdding = {
                    2: '-',
                    3: '-',
                    4: '-'
                }
                if (typeof window.keycloak === 'object' && window.keycloak !== null) {
                    if (typeof window.keycloak.tokenParsed === 'object' && window.keycloak.tokenParsed !== null) {
                        if (typeof window.keycloak.tokenParsed.cr_membernumber === 'string' && window.keycloak.tokenParsed.cr_membernumber.length) {
                            dataAdding[2] = window.keycloak.tokenParsed.cr_membernumber.substr(0, 3)
                            dataAdding[3] = window.keycloak.tokenParsed.cr_membernumber
                        }
                        if (typeof window.keycloak.tokenParsed.cr_id === 'string' && window.keycloak.tokenParsed.cr_id.length) {
                            dataAdding[4] = 'xxx' // Hashing is too much overhead
                        }
                    }
                }

                dataExisting = getEmptyTrackingParametersFilled(dataExisting, dataAdding)
                dataExisting.loginStatus = dataAdding[4] === '-' ? 'not_logged_in' : 'logged_in'
                window.wtSmart.session.data.set(dataExisting)
            }

            const appendWebtrekkPageData = function () {
                let categoryDataExisting = window.wtSmart.page.category.get()
                let pageDataExisting = window.wtSmart.page.parameter.get()
                if (areAllTrackingParametersSet(categoryDataExisting, [1, 3, 4])
                    && areAllTrackingParametersSet(pageDataExisting, [108, 109])) {
                    return
                }

                const categoryDataAdding = {
                    1: 'meinecreditreform',
                    3: '-',
                    4: 'de'
                }
                if (window.websiteArea && typeof window.websiteArea === 'string' && window.websiteArea.length) {
                    categoryDataAdding[3] = window.websiteArea
                } else {
                    const scsElement = document.querySelector("div.mc-mf--content-wrapper > div[id^='single-spa-application:@']")
                    if (scsElement && typeof scsElement.id === 'string' && scsElement.id.length > 24) {
                        categoryDataAdding[3] = scsElement.id.substr(24)
                    }
                }
                let preferredLanguage = ''
                if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                    let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                    if (langCode && langCode.length) {
                        preferredLanguage = langCode
                    }
                }
                if (typeof preferredLanguage === 'string' && preferredLanguage.length) {
                    const reportingLanguage = preferredLanguage.split('_')
                    if (reportingLanguage.length === 2) {
                        categoryDataAdding[4] = reportingLanguage[1].toLowerCase().replace('us', 'en')
                    } else {
                        categoryDataAdding[4] = reportingLanguage[0].toLowerCase()
                    }
                }

                categoryDataExisting = getEmptyTrackingParametersFilled(categoryDataExisting, categoryDataAdding)
                pageDataExisting = getEmptyTrackingParametersFilled(pageDataExisting, {
                    108: categoryDataAdding[1],
                    109: categoryDataAdding[4]
                })
                window.wtSmart.page.category.set(categoryDataExisting)
                window.wtSmart.page.parameter.set(pageDataExisting)
            }

            const appendWebtrekkActionData = function () {
                let dataExisting = window.wtSmart.action.parameter.get()
                if (!dataExisting || Object.keys(dataExisting).length === 0) {
                    return // Prevent setting parameters, if it is obviously no action tracking
                }
                if (areAllTrackingParametersSet(dataExisting, [20, 21]) === true) {
                    return
                }

                const dataAdding = {
                    20: 'meinecreditreform',
                    21: 'de'
                }
                let preferredLanguage = ''
                if (document.cookie.indexOf('CAS_PREFERRED_LANGUAGE') !== -1) {
                    let langCode = document.cookie.split('CAS_PREFERRED_LANGUAGE=')[1].split(';')[0]
                    if (langCode && langCode.length) {
                        preferredLanguage = langCode
                    }
                }
                if (typeof preferredLanguage === 'string' && preferredLanguage.length) {
                    const reportingLanguage = preferredLanguage.split('_')
                    if (reportingLanguage.length === 2) {
                        if (preferredLanguage.toLowerCase() === 'en_us') {
                            dataAdding[21] = 'en' // Exception instead of reporting as en-us
                        } else if (preferredLanguage.toLowerCase() === 'de_at') {
                            dataAdding[21] = 'at' // Exception instead of reporting as de-at
                        } else if (reportingLanguage[0].toLowerCase() === reportingLanguage[1].toLowerCase()) {
                            dataAdding[21] = reportingLanguage[0].toLowerCase() // de_DE is sent as de
                        } else {
                            dataAdding[21] = reportingLanguage[0].toLowerCase() + '-' + reportingLanguage[1].toLowerCase() // e.g. fr-ch, de-ch, it-ch
                        }
                    } else {
                        dataAdding[21] = reportingLanguage[0].toLowerCase()
                    }
                }

                dataExisting = getEmptyTrackingParametersFilled(dataExisting, dataAdding)
                window.wtSmart.action.parameter.set(dataExisting)
            }

            /**
             * @param {Object.<number, string>} data
             * @param {Array.<number>} parameters
             * @returns {boolean}
             */
            const areAllTrackingParametersSet = function (data, parameters) {
                if (!data) {
                    return false
                }

                let allSet = true

                parameters.forEach(parameter => {
                    if (!data[parameter]) {
                        allSet = false
                    }
                })

                return allSet
            }

            /**
             * @param {{Object.<number, string>}} data
             * @param {{Object.<number, string>}} parameters
             * @returns {{Object.<number, string>}}
             */
            const getEmptyTrackingParametersFilled = function (data, parameters) {
                if (!data) {
                    data = {}
                }
                if (!parameters) {
                    return data
                }

                Object.keys(parameters).forEach(parameter => {
                    if (!data[parseInt(parameter)]) {
                        data[parseInt(parameter)] = parameters[parseInt(parameter)]
                    }
                })

                return data
            }

            const isWebtrekkActive = function () {
                return typeof window.wtSmart === 'object' && window.wtSmart !== null
            }

            const appendWebtrekkDataAndSend = function (fnName, args) {
                if (isWebtrekkActive()) {
                    if (!isAppendingWebtrekkData) {
                        isAppendingWebtrekkData = true
                        appendWebtrekkSessionData()
                        if (['track', 'trackPage'].includes(fnName)) {
                            appendWebtrekkPageData()
                        }
                        if (['track', 'trackAction'].includes(fnName)) {
                            appendWebtrekkActionData()
                        }
                    }
                    wtSmart[`_${fnName}`].call(...args)
                    isAppendingWebtrekkData = false
                }
            }

            wtSmart._track = wtSmart.track
            wtSmart._trackAction = wtSmart.trackAction
            wtSmart._trackPage = wtSmart.trackPage
            wtSmart.track = function (fn) {
                checkCookieAndTrack('track', arguments)
            }
            wtSmart.trackAction = function (fn) {
                checkCookieAndTrack('trackAction', arguments)
            }
            wtSmart.trackPage = function (fn) {
                checkCookieAndTrack('trackPage', arguments)
            }
        }
    }, 200)
}
