"use strict";

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.Accordion = {
  init: function init() {
    var _this = this;

    var accordions = document.querySelectorAll('.crefo-ui-accordion');

    if (accordions && accordions.length) {
      var _loop = function _loop(i) {
        var accordion = accordions[i];
        var items = accordion.querySelectorAll('.opener');

        for (var j = 0; j < items.length; j++) {
          if (items[j].getAttribute('data-crefo-ui') !== 'true') {
            items[j].addEventListener('click', function (e) {
              this.toggleClickedAndCloseOthers(accordion, e.target.parentElement);
            }.bind(_this));
            items[j].setAttribute('data-crefo-ui', 'true');
          }
        }
      };

      for (var i = 0; i < accordions.length; i++) {
        _loop(i);
      }
    }
  },
  toggleClickedAndCloseOthers: function toggleClickedAndCloseOthers(accordion, clickedItem) {
    var items = accordion.querySelectorAll('li');

    for (var i = 0; i < items.length; i++) {
      if (items[i] !== clickedItem) {
        items[i].classList.remove('active');
      }
    }

    clickedItem.classList.toggle('active');
  }
};
CrefoUI.Accordion.init();

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.ContentTabs = {
  opener: [],
  init: function init() {
    var _this2 = this;

    var contentTabsWrapper = document.querySelectorAll('.crefo-ui-tabbedcontent');

    if (contentTabsWrapper && contentTabsWrapper.length) {
      for (var i = 0; i < contentTabsWrapper.length; i++) {
        if (contentTabsWrapper[i].getAttribute('data-crefo-ui') !== 'true') {
          (function () {
            var contentTabWrapper = contentTabsWrapper[i];
            var activeTab = false;
            var tabsNavHTML = '<span class="tabs-nav prev"><i class="crefo-ui-icon icon-chevron-prev" aria-hidden="true"></i></span><span class="tabs-nav next"><i class="crefo-ui-icon icon-chevron-next" aria-hidden="true"></i></span>';
            contentTabWrapper.innerHTML = tabsNavHTML + contentTabWrapper.innerHTML;
            _this2.opener = contentTabWrapper.querySelectorAll('.tab');
            setTimeout(function () {
              for (var j = 0; j < this.opener.length; j++) {
                if (this.opener[j].classList.contains('active')) {
                  activeTab = true;
                }

                this.opener[j].addEventListener('click', function (e) {
                  this.openClickedAndCloseOthers(contentTabWrapper, e.target);
                }.bind(this));
              }

              if (!activeTab) {
                this.opener[0].classList.add('active');
                contentTabWrapper.querySelector('.tab-content').classList.add('active');
              }
            }.bind(_this2), 0);
          })();
        }

        contentTabsWrapper[i].setAttribute('data-crefo-ui', 'true');
      }
    }

    setTimeout(function () {
      this.setScrollTabsIfRequired();
    }.bind(this), 300);
    window.addEventListener('resize', this.setScrollTabsIfRequired, true);
  },
  openClickedAndCloseOthers: function openClickedAndCloseOthers(contentTabWrapper, clickedItem) {
    var tabOpener = contentTabWrapper.querySelectorAll('.tab');
    var tabContent = contentTabWrapper.querySelectorAll('.tab-content');

    for (var i = 0; i < tabOpener.length; i++) {
      if (tabOpener[i] !== clickedItem) {
        tabOpener[i].classList.remove('active');
        tabContent[i].classList.remove('active');
      } else {
        tabOpener[i].classList.add('active');
        tabContent[i].classList.add('active');
      }
    }
  },
  setScrollTabsIfRequired: function setScrollTabsIfRequired() {
    var _this3 = this;

    var supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    var contentTabsWrapper = document.querySelectorAll('.crefo-ui-tabbedcontent');

    if (contentTabsWrapper && contentTabsWrapper.length) {
      var _loop2 = function _loop2(i) {
        var contentTabWrapper = contentTabsWrapper[i];
        var tabsWrapper = contentTabWrapper.querySelector('.tabs-wrapper');
        var wrapperWidth = contentTabWrapper.clientWidth;
        var tabsWidth = 0;

        for (var j = 0; j < _this3.opener.length; j++) {
          tabsWidth += _this3.opener[j].clientWidth;
        }

        var tabsNav = contentTabWrapper.querySelectorAll('.tabs-nav');

        for (var k = 0; k < tabsNav.length; k++) {
          if (tabsNav[k].getAttribute('data-crefo-ui') !== 'true') {
            tabsNav[k].addEventListener('click', function (e) {
              var pos = e.target.classList.contains('prev') || e.target.classList.contains('icon-chevron-prev') ? tabsWrapper.scrollLeft - 120 : tabsWrapper.scrollLeft + 120; // native smooth scrolling for Chrome, Firefox & Opera

              if (supportsNativeSmoothScroll) {
                tabsWrapper.scroll({
                  behavior: 'smooth',
                  left: pos,
                  top: 0
                });
              } else {
                tabsWrapper.scrollLeft = pos;
              }
            });

            if (tabsWidth > wrapperWidth) {
              tabsNav[k].classList.add('active');
            } else {
              tabsNav[k].classList.remove('active');
            }

            tabsNav[k].setAttribute('data-crefo-ui', 'true');
          }
        }

        if (tabsWidth > wrapperWidth) {
          tabsWrapper.classList.add('tab-nav-active');
        } else {
          tabsWrapper.classList.remove('tab-nav-active');
        }
      };

      for (var i = 0; i < contentTabsWrapper.length; i++) {
        _loop2(i);
      }
    }
  }
};
CrefoUI.ContentTabs.init();

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.Header = {
  loginMenuOpen: false,
  mainSubMenuOpen: false,
  backdropOpen: false,
  fixedClass: 'scrollspy-fixed',
  activeClass: 'backdrop-active',
  init: function init() {
    var mobile = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

    if (!!document.querySelector('.header-nav')) {
      this.initServiceMenuSubmenu();

      if (!document.querySelector('.header-secondary')) {
        this.initMinHeader();

        if (!!document.querySelector('.header-nav .service-menu' && mobile)) {
          this.initMobileMenu();
        }
      } else {
        this.initScrollHeader();
        this.initHeaderMainMenu();
        this.initHeaderLoginMenu();
        this.setContentMarginTop(121);

        if (!!document.querySelector('.header-nav .main-menu' && mobile)) {
          this.initMobileMenu();
        }
      }
    }

    document.body.addEventListener('click', function backDropToggle(e) {
      if (e.target.classList.contains(this.activeClass)) {
        this.closeBackdropAndSubmenues(true);
        document.body.removeEventListener('click', backDropToggle);
      }
    }.bind(this));
  },
  initScrollHeader: function initScrollHeader() {
    var lastKnownScrollPos = 0;
    var ticking = false;
    var header = document.querySelector('.header-nav');
    var hasScrollSpy = header.classList.contains('scrollspy');

    if (hasScrollSpy && header.getAttribute('data-crefo-ui') !== 'true') {
      window.addEventListener('scroll', function () {
        lastKnownScrollPos = window.pageYOffset; // Since scroll events are expensive, throttle execution

        if (!ticking) {
          window.requestAnimationFrame(function () {
            this.setStickyHeader(lastKnownScrollPos);
            ticking = false;
          }.bind(this));
          ticking = true;
        }
      }.bind(this));
      header.setAttribute('data-crefo-ui', 'true');
    }
  },
  setStickyHeader: function setStickyHeader(scrollPos) {
    var header = document.querySelector('.header-nav');

    if (!header.classList.contains(this.fixedClass) && scrollPos > document.querySelector('.header-primary').clientHeight) {
      header.classList.add(this.fixedClass);
    } else if (scrollPos < header.clientHeight) {
      header.classList.remove(this.fixedClass);
    }
  },
  setBackdrop: function setBackdrop(show) {
    if (show) {
      this.backdropOpen = true;
      document.body.classList.add(this.activeClass);
    } else {
      this.backdropOpen = false;
      document.body.classList.remove(this.activeClass);
    }
  },
  closeBackdropAndSubmenues: function closeBackdropAndSubmenues(withLoginMenu) {
    if (withLoginMenu && this.loginMenuOpen) {
      if (this.backdropOpen) {
        this.setBackdrop(false);
      }

      document.querySelector('.login-menu').classList.remove('active');
      this.loginMenuOpen = !this.loginMenuOpen;
    }

    if (this.mainSubMenuOpen) {
      if (this.backdropOpen) {
        this.setBackdrop(false);
      }

      var headerDropdownOpener = document.querySelectorAll('.main-menu .menu-item.has-submenu');

      for (var i = 0; i < headerDropdownOpener.length; i++) {
        headerDropdownOpener[i].classList.remove('active');
      }

      this.mainSubMenuOpen = !this.mainSubMenuOpen;
    }
  },
  initHeaderMainMenu: function initHeaderMainMenu() {
    var headerDropdownOpener = document.querySelectorAll('.main-menu .menu-item.has-submenu');

    if (headerDropdownOpener && headerDropdownOpener.length) {
      for (var i = 0; i < headerDropdownOpener.length; i++) {
        if (headerDropdownOpener[i].getAttribute('data-crefo-ui') !== 'true') {
          headerDropdownOpener[i].addEventListener('click', function (e) {
            var elem = e.target;

            if (elem.nodeName !== 'LI') {
              while (elem.nodeName !== 'LI') {
                elem = elem.parentElement;
              }
            }

            if (!elem.classList.contains('has-submenu')) {
              e.preventDefault();
            }

            var clickedElemIsCurrentlyActive = elem.classList.contains('active');
            this.closeBackdropAndSubmenues(true);
            this.mainSubMenuOpen = !this.mainSubMenuOpen;

            if (!clickedElemIsCurrentlyActive) {
              elem.classList.add('active');
              this.setBackdrop(true);
            } else {
              elem.classList.remove('active');
              this.setBackdrop(false);
            }
          }.bind(this));
          headerDropdownOpener[i].setAttribute('data-crefo-ui', 'true');
        }
      }
    }
  },
  initServiceMenuSubmenu: function initServiceMenuSubmenu() {
    var submenuItems = document.querySelectorAll('.service-menu .menu-item.has-submenu');

    if (submenuItems && submenuItems.length) {
      for (var i = 0; i < submenuItems.length; i++) {
        var submenuItem = submenuItems[i];
        var opener = submenuItem.querySelector('a');

        if (opener.getAttribute('data-crefo-ui') !== 'true') {
          opener.addEventListener('click', function (e) {
            e.preventDefault();
            var submenu = e.target.parentElement;

            if (!submenu.classList.contains('has-submenu')) {
              while (!submenu.classList.contains('has-submenu')) {
                submenu = submenu.parentElement;
              }
            }

            submenu.querySelector('.service-submenu').classList.toggle('active');
          });
          opener.setAttribute('data-crefo-ui', 'true');
        }
      }
    }
  },
  initHeaderLoginMenu: function initHeaderLoginMenu() {
    var headerDropdownWrapper = document.querySelector('.login-menu');
    var headerDropdownOpener = document.querySelector('.login-menu--opener');

    if (headerDropdownWrapper && headerDropdownOpener && headerDropdownOpener.getAttribute('data-crefo-ui') !== 'true') {
      headerDropdownOpener.addEventListener('click', function (e) {
        e.preventDefault();
        this.closeBackdropAndSubmenues(false);
        this.loginMenuOpen = !this.loginMenuOpen;
        headerDropdownWrapper.classList.toggle('active');
        this.setBackdrop(!this.backdropOpen);
      }.bind(this));
      headerDropdownOpener.setAttribute('data-crefo-ui', 'true');
    }
  },
  initMinHeader: function initMinHeader() {
    document.querySelector('.header-nav').classList.add('fixed');
    this.setContentMarginTop(70);
  },
  initMobileMenu: function initMobileMenu() {
    var opener = document.querySelector('.mobile-main-menu-opener');
    var mainMenu = document.querySelector('.header-nav .main-menu');
    var serviceLinks = document.querySelectorAll('.service-menu > li:not(.has-submenu) > a');

    if (opener && opener.getAttribute('data-crefo-ui') !== 'true') {
      opener.addEventListener('click', function (e) {
        e.preventDefault();
        var elem = e.target;

        if (elem.nodeName !== 'I') {
          while (elem.nodeName !== 'I') {
            elem = elem.firstElementChild;
          }
        }

        var mobileSubmenuItems = document.querySelectorAll('nav.mobile-nav-wrapper li.has-submenu');

        for (var i = 0; i < mobileSubmenuItems.length; i++) {
          mobileSubmenuItems[i].classList.remove('submenu-active');
        }

        document.body.classList.toggle('mobile-menu-opened');
        elem.classList.toggle('icon-mobile-menu');
        elem.classList.toggle('icon-close');
      });
      opener.setAttribute('data-crefo-ui', 'true');
    }

    var mobileNavWrapper = document.createElement('nav');
    mobileNavWrapper.className = 'mobile-nav-wrapper';
    var mobileNav = document.createElement('ul');

    if (mainMenu) {
      document.querySelector('.header-nav').classList.add('has-mainmenu');
      var topLevelItems = mainMenu.querySelectorAll('.menu-item:not(.show-if-sticky)');

      for (var i = 0; i < topLevelItems.length; i++) {
        var topLevelItem = topLevelItems[i];
        var hasSubmenu = topLevelItem.classList.contains('has-submenu');
        var mobileNavItem = document.createElement('li');
        mobileNavItem.className = 'mobile-menu-item';

        if (hasSubmenu) {
          mobileNavItem.className += ' has-submenu';
          mobileNavItem.addEventListener('click', function (e) {
            e.preventDefault();
            e.target.parentNode.classList.add('submenu-active');
          });
        }

        var mobileNavItemText = document.createElement('a');
        mobileNavItemText.href = topLevelItem.querySelector('a').getAttribute('href');
        mobileNavItemText.textContent = topLevelItem.querySelector('a').textContent;
        mobileNavItem.appendChild(mobileNavItemText);
        var topLevelSubmenuItems = topLevelItem.querySelectorAll('.main-menu--slide a');

        if (hasSubmenu && topLevelSubmenuItems && topLevelSubmenuItems.length) {
          var mobileSubmenuWrapper = document.createElement('div');
          mobileSubmenuWrapper.className = 'submenu-wrapper';
          var mobileSubmenuList = document.createElement('ul');
          var mobileSubmenuListBackItem = document.createElement('li');
          mobileSubmenuListBackItem.className = 'mobile-submenu-item back';
          var mobileSubmenuListBackLink = document.createElement('a');
          mobileSubmenuListBackLink.href = '#';
          mobileSubmenuListBackLink.className = 'link-icon-prev';
          var mobileSubmenuListBackLinkText = document.createElement('span');
          mobileSubmenuListBackLinkText.textContent = 'Zurück';
          mobileSubmenuListBackLink.appendChild(mobileSubmenuListBackLinkText);
          mobileSubmenuListBackItem.appendChild(mobileSubmenuListBackLink);
          mobileSubmenuListBackItem.addEventListener('click', function (e) {
            e.preventDefault();
            var topLevelItem = e.target.parentElement;

            if (!topLevelItem.classList.contains('mobile-menu-item')) {
              while (!topLevelItem.classList.contains('mobile-menu-item')) {
                topLevelItem = topLevelItem.parentElement;
              }
            }

            topLevelItem.classList.remove('submenu-active');
          });
          mobileSubmenuList.appendChild(mobileSubmenuListBackItem);

          for (var j = 0; j < topLevelSubmenuItems.length; j++) {
            var topLevelSubmenuItem = topLevelSubmenuItems[j];
            var mobileSubmenuListItem = document.createElement('li');
            mobileSubmenuListItem.className = 'mobile-submenu-item';
            mobileSubmenuListItem.innerHTML = topLevelSubmenuItem.outerHTML;
            mobileSubmenuList.appendChild(mobileSubmenuListItem);
          }

          if (serviceLinks) {
            for (var k = 0; k < serviceLinks.length; k++) {
              var $serviceLink = serviceLinks[k];
              var mobileServiceNavItem = document.createElement('li');
              mobileServiceNavItem.className = 'mobile-submenu-item service-menu-item';
              mobileServiceNavItem.appendChild($serviceLink.cloneNode(true));
              mobileSubmenuList.appendChild(mobileServiceNavItem);
            }
          }

          mobileSubmenuWrapper.appendChild(mobileSubmenuList);
          mobileNavItem.appendChild(mobileSubmenuWrapper);
        }

        mobileNav.appendChild(mobileNavItem);
      }
    }

    if (serviceLinks) {
      document.querySelector('.header-nav').classList.add('has-servicemenu');

      for (var m = 0; m < serviceLinks.length; m++) {
        var serviceLink = serviceLinks[m];

        var _mobileServiceNavItem = document.createElement('li');

        _mobileServiceNavItem.className = 'mobile-menu-item service-menu-item';

        _mobileServiceNavItem.appendChild(serviceLink.cloneNode(true));

        mobileNav.appendChild(_mobileServiceNavItem);
      }
    }

    if (mainMenu || serviceLinks) {
      mobileNavWrapper.appendChild(mobileNav);
      var wrapper = document.querySelector('.mobile-nav-wrapper');

      if (!wrapper) {
        document.body.appendChild(mobileNavWrapper);
      } else {
        document.body.removeChild(wrapper);
        document.body.appendChild(mobileNavWrapper);
      }
    }
  },
  setContentMarginTop: function setContentMarginTop(int) {
    var main = document.querySelector('.main');

    if (main) {
      main.style.marginTop = int + 'px';
    }
  }
};
CrefoUI.Header.init();

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.Input = {
  init: function init() {
    var _this4 = this;

    this.initPwMeter();
    var textInputs = document.querySelectorAll("input:not([data-no-crefo-ui='true'])[type='text']");
    var emailInputs = document.querySelectorAll("input[type='email']");
    var passwordInputs = document.querySelectorAll(".has-pwmeter input[type='password'], .has-visible-toggle-and-pwmeter input[type='password']");
    var passwordToggles = document.querySelectorAll(".has-visible-toggle .pw-toggle, .has-visible-toggle-and-pwmeter .pw-toggle");
    var newPasswordInputs = document.querySelectorAll(".has-visible-toggle-and-pwmeter");
    /**
     * Add markup for password strength indicator
     */
    // for (let i = 0; i < newPasswordInputs.length; i++) {
    //   if (newPasswordInputs[i].getAttribute('data-crefo-ui') !== 'true') {
    //     let div = document.createElement('div')
    //     div.className = 'pw-indicator row'
    //     let indicator = document.createElement('div')
    //     indicator.className = 'indicator col-10'
    //     let weak = document.createElement('span')
    //     weak.className = 'weak'
    //     let medium = document.createElement('span')
    //     medium.className = 'medium'
    //     let strong = document.createElement('span')
    //     strong.className = 'strong'
    //     indicator.appendChild(weak)
    //     indicator.appendChild(medium)
    //     indicator.appendChild(strong)
    //     let indicatorText = document.createElement('div')
    //     indicatorText.className = 'indicator-text col-2'
    //     div.appendChild(indicator)
    //     div.appendChild(indicatorText)
    //     newPasswordInputs[i].appendChild(div)
    //     newPasswordInputs[i].setAttribute('data-crefo-ui', 'true')
    //   }
    // }

    /**
     * Keyhandler for text input fields
     */

    for (var i = 0; i < textInputs.length; i++) {
      if (textInputs[i].getAttribute('data-crefo-ui') !== 'true') {
        textInputs[i].addEventListener('keyup', function (e) {
          var groupWrapper = _this4.getInputGroupWrapper(e.target);

          if (!e.target.value.length) {
            groupWrapper.classList.remove('is-valid');
            groupWrapper.classList.remove('has-error');

            if (e.target.required) {
              _this4.setErrorMessage(groupWrapper, 'required');

              groupWrapper.classList.add('has-error');
            }
          } else {
            var pattern = e.target.pattern;
            var patternMsg = '';

            var maxLength = _this4.getLengthAttr(e.target, 'max');

            var minLength = _this4.getLengthAttr(e.target, 'min');

            var validMaxLength = true;
            var validMinLength = true;
            var validPattern = true;

            if (pattern && pattern.length) {
              patternMsg = e.target.title;
              var regex = RegExp(e.target.pattern);
              validPattern = regex.test(e.target.value);
            }

            if (maxLength && maxLength !== -1 && e.target.value.length > maxLength) {
              validMaxLength = false;
            }

            if (minLength && minLength !== -1 && e.target.value.length < minLength) {
              validMinLength = false;
            }

            if (!validMaxLength || !validMinLength || !validPattern) {
              if (!validPattern) {
                _this4.setErrorMessage(groupWrapper, 'pattern', patternMsg);
              }

              if (!validMinLength) {
                _this4.setErrorMessage(groupWrapper, 'minLength', minLength);
              }

              if (!validMaxLength) {
                _this4.setErrorMessage(groupWrapper, 'maxLength', maxLength);
              }

              groupWrapper.classList.add('has-error');
              groupWrapper.classList.remove('is-valid');
            } else {
              groupWrapper.classList.remove('has-error');
              groupWrapper.classList.add('is-valid');
            }
          }
        });
        textInputs[i].setAttribute('data-crefo-ui', 'true');
      }
    }
    /**
     * Keyhandler for email input fields
     */


    for (var _i = 0; _i < emailInputs.length; _i++) {
      if (emailInputs[_i].getAttribute('data-crefo-ui') !== 'true') {
        emailInputs[_i].addEventListener('keyup', function (e) {
          var groupWrapper = _this4.getInputGroupWrapper(e.target);

          if (!e.target.value.length) {
            groupWrapper.classList.remove('is-valid');

            if (e.target.required) {
              _this4.setErrorMessage(groupWrapper, 'required');

              groupWrapper.classList.add('has-error');
            }
          } else {
            if (_this4.validateHtml5Email(e.target.value)) {
              groupWrapper.classList.remove('has-error');
              groupWrapper.classList.add('is-valid');
            } else {
              groupWrapper.classList.add('has-error');
              groupWrapper.classList.remove('is-valid');
            }
          }
        });

        emailInputs[_i].setAttribute('data-crefo-ui', 'true');
      }
    }
    /**
     * Clickhandler for password input fields with type (text/password) toggle
     */


    var _loop3 = function _loop3(_i2) {
      if (passwordToggles[_i2].getAttribute('data-crefo-ui') !== 'true') {
        passwordToggles[_i2].addEventListener('click', function (e) {
          e.preventDefault();

          var groupWrapper = _this4.getInputGroupWrapper(e.target);

          groupWrapper.querySelector('.input-wrapper').classList.toggle('password-visible');
          var input = groupWrapper.querySelector('input');
          input.type = input.type === 'password' ? 'text' : 'password';
          passwordToggles[_i2].title = input.type === 'text' ? 'Passwort verbergen' : 'Passwort anzeigen';
        });

        passwordToggles[_i2].setAttribute('data-crefo-ui', 'true');
      }
    };

    for (var _i2 = 0; _i2 < passwordToggles.length; _i2++) {
      _loop3(_i2);
    }
    /**
     * Keyhandler for password input fields with password-meter
     */


    for (var _i3 = 0; _i3 < passwordInputs.length; _i3++) {
      if (passwordInputs[_i3].getAttribute('data-crefo-ui') !== 'true') {
        passwordInputs[_i3].addEventListener('keyup', function (e) {
          var groupWrapper = _this4.getInputGroupWrapper(e.target);

          if (!e.target.value.length) {
            e.target.parentElement.classList.add('pwmeter-red');
            e.target.parentElement.classList.remove('pwmeter-yellow', 'pwmeter-green');

            if (e.target.required) {
              _this4.setErrorMessage(groupWrapper, 'required');

              groupWrapper.classList.add('has-error');
            }
          } else {
            if (e.target.value.length < 8) {
              e.target.parentElement.classList.add('pwmeter-red');
              e.target.parentElement.classList.remove('pwmeter-yellow', 'pwmeter-green');

              _this4.setErrorMessage(groupWrapper, 'minLength', 8);

              groupWrapper.classList.add('has-error');
            } else {
              if (!_this4.validatePasswordRequirements(e.target.value)) {
                e.target.parentElement.classList.add('pwmeter-red');
                e.target.parentElement.classList.remove('pwmeter-yellow', 'pwmeter-green');

                _this4.setErrorMessage(groupWrapper, 'password');

                groupWrapper.classList.add('has-error');
              } else {
                var pwScore = _this4.passwordMeterScore(e.target.value).score;

                groupWrapper.classList.remove('has-error');
                e.target.parentElement.classList.remove('pwmeter-red', 'pwmeter-yellow', 'pwmeter-green');

                if (pwScore <= 60) {
                  e.target.parentElement.classList.add('pwmeter-yellow');
                } else if (pwScore > 60) {
                  e.target.parentElement.classList.add('pwmeter-green');
                }
              }
            }
          }
        });

        passwordInputs[_i3].setAttribute('data-crefo-ui', 'true');
      }
    }
  },

  /**
   * Based on http://www.passwordmeter.com/ created by Jeff Todnem
   *
   * Created by: Stephan Schmitz <stephan.schmitz@sunzinet.com>
   * Created on: 2019-05-02
   *
   * License Information:
   * -------------------------------------------------------------------------
   *
   *
   * This program is free software; you can redistribute it and/or modify it
   * under the terms of the GNU General Public License as published by the
   * Free Software Foundation; either version 2 of the License, or (at your
   * option) any later version.
   *
   * This program is distributed in the hope that it will be useful, but
   * WITHOUT ANY WARRANTY; without even the implied warranty of
   * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU
   * General Public License for more details.
   *
   * You should have received a copy of the GNU General Public License along
   * with this program; if not, write to the Free Software Foundation, Inc.,
   * 59 Temple Place, Suite 330, Boston, MA 02111-1307 USA
   *
   **/
  passwordMeterScore: function passwordMeterScore(h) {
    var k = 0,
        l = 0,
        m = 0,
        p = 0,
        r = 0,
        d,
        H = 0,
        I = 0,
        E = 0,
        w = d = 0,
        x = 0,
        y = 0,
        c = 0,
        e = 0,
        z = 0,
        A = 0,
        B = 0,
        C = 0,
        g = "",
        t = "",
        n = "",
        G = "",
        J = 0,
        K = 0,
        L = 0,
        M = 0,
        N = 0,
        O = 0,
        P = 0,
        Q = 0,
        R = 0,
        S = 0,
        T = 0,
        U = 0,
        V = 0,
        W = 0,
        X = 0,
        u = 1;

    if (h) {
      var a = parseInt(4 * h.length);
      var f = h.length;

      for (var q = h.replace(/\s+/g, "").split(/\s*/), v = q.length, b = 0; b < v; b++) {
        q[b].match(/[A-Z]/g) ? ("" !== g && g + 1 === b && (w++, e++), g = b, k++) : q[b].match(/[a-z]/g) ? ("" !== t && t + 1 === b && (x++, e++), t = b, l++) : q[b].match(/[0-9]/g) ? (0 < b && b < v - 1 && r++, "" !== n && n + 1 === b && (y++, e++), n = b, m++) : q[b].match(/[^a-zA-Z0-9_]/g) && (0 < b && b < v - 1 && r++, "" !== G && G + 1 === b && (c++, e++), G = b, p++);
        var F = !1;

        for (var D = 0; D < v; D++) {
          q[b] === q[D] && b !== D && (F = !0, d += Math.abs(v / (D - b)));
        }

        F && (E++, d = (F = v - E) ? Math.ceil(d / F) : Math.ceil(d));
      }

      for (c = 0; 23 > c; c++) {
        if (e = "abcdefghijklmnopqrstuvwxyz".substring(c, parseInt(c + 3)), g = e.split("").reverse().join(""), -1 !== h.toLowerCase().indexOf(e) || -1 !== h.toLowerCase().indexOf(g)) z++, C++;
      }

      for (c = 0; 8 > c; c++) {
        if (e = "01234567890".substring(c, parseInt(c + 3)), g = e.split("").reverse().join(""), -1 !== h.toLowerCase().indexOf(e) || -1 !== h.toLowerCase().indexOf(g)) A++, C++;
      }

      for (c = 0; 8 > c; c++) {
        if (e = "!\"#$%&'()*+,-./:;<=>?[\\]^`{|}~".substring(c, parseInt(c + 3)), g = e.split("").reverse().join(""), -1 !== h.toLowerCase().indexOf(e) || -1 !== h.toLowerCase().indexOf(g)) B++, C++;
      }

      C = a;
      0 < k && k < f && (a = parseInt(a + 2 * (f - k)), J = parseInt(2 * (f - k)));
      0 < l && l < f && (a = parseInt(a + 2 * (f - l)), K = parseInt(2 * (f - l)));
      0 < m && m < f && (a = parseInt(a + 4 * m), L = parseInt(4 * m));
      0 < p && (a = parseInt(a + 6 * p), M = parseInt(6 * p));
      0 < r && (a = parseInt(a + 2 * r), N = parseInt(2 * r));
      (0 < l || 0 < k) && 0 === p && 0 === m && (a = parseInt(a - f), H = f, P = -f);
      0 === l && 0 === k && 0 === p && 0 < m && (a = parseInt(a - f), I = f, Q = -f);
      0 < E && (a = parseInt(a - d), R = -d);
      0 < w && (a = parseInt(a - 2 * w), S = -parseInt(2 * w));
      0 < x && (a = parseInt(a - 2 * x), T = -parseInt(2 * x));
      0 < y && (a = parseInt(a - 2 * y), U = -parseInt(2 * y));
      0 < z && (a = parseInt(a - 3 * z), V = -parseInt(3 * z));
      0 < A && (a = parseInt(a - 3 * A), W = -parseInt(3 * A));
      0 < B && (a = parseInt(a - 3 * B), X = -parseInt(3 * B));
      d = [f, k, l, m, p];
      c = ["nLength", "nAlphaUC", "nAlphaLC", "nNumber", "nSymbol"];
      e = d.length;

      for (n = g = 0; n < e; n++) {
        t = "nLength" === c[n] ? 7 : 0, d[n] === parseInt(t + 1) ? g++ : d[n] > parseInt(t + 1) && g++;
      }

      d = g;
      d > (8 <= h.length ? 3 : 4) && (a = parseInt(a + 2 * d), O = parseInt(2 * d));
      100 < a ? a = 100 : 0 > a && (a = 0);
      0 <= a && 20 > a ? u = 1 : 20 <= a && 40 > a ? u = 2 : 40 <= a && 60 > a ? u = 3 : 60 <= a && 80 > a ? u = 4 : 80 <= a && 100 >= a && (u = 5);
      return {
        score: a,
        complexity: u,
        additions: {
          numberOfCharacters: {
            count: f,
            bonus: C
          },
          uppercaseLetters: {
            count: k,
            bonus: J
          },
          lowercaseLetters: {
            count: l,
            bonus: K
          },
          numbers: {
            count: m,
            bonus: L
          },
          symbols: {
            count: p,
            bonus: M
          },
          middleNumbersOrSymbols: {
            count: r,
            bonus: N
          },
          requirements: {
            count: d,
            bonus: O
          }
        },
        deductions: {
          lettersOnly: {
            count: H,
            bonus: P
          },
          numbersOnly: {
            count: I,
            bonus: Q
          },
          repeatCharacters: {
            count: E,
            bonus: R
          },
          consecutiveUppercaseLetters: {
            count: w,
            bonus: S
          },
          consecutiveLowercaseLetters: {
            count: x,
            bonus: T
          },
          consecutiveNumbers: {
            count: y,
            bonus: U
          },
          sequentialLetters: {
            count: z,
            bonus: V
          },
          sequentialNumbers: {
            count: A,
            bonus: W
          },
          sequentialSymbols: {
            count: B,
            bonus: X
          }
        }
      };
    }

    return !1;
  },
  initPwMeter: function initPwMeter() {
    var pwmeterFieldWrapper = document.querySelectorAll(".has-pwmeter");

    for (var i = 0; i < pwmeterFieldWrapper.length; i++) {
      pwmeterFieldWrapper[i].classList.add('pwmeter-red');
    }
  },
  getInputGroupWrapper: function getInputGroupWrapper(element) {
    var inputGroupClass = 'input-group';
    var parent = element.parentElement;

    if (!parent.classList.contains(inputGroupClass)) {
      while (!parent.classList.contains(inputGroupClass)) {
        parent = parent.parentElement;
      }
    }

    return parent;
  },
  validatePasswordRequirements: function validatePasswordRequirements(str) {
    var regex = new RegExp("^(?=.*[a-zßäöüêéèëáàæôòûùœçîï])(?=.*[A-ZÄÖÜÊÉÈËÁÀÆÔÒÛÙŒÇÎÏ])(?=.*[0-9!@#\$%\^&\*])(?=.{8,})");
    return regex.test(str);
  },
  validateHtml5Email: function validateHtml5Email(str) {
    // https://www.w3.org/TR/html5/forms.html#valid-e-mail-address
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(str);
  },
  setErrorMessage: function setErrorMessage(inputGroupWrapper, errType, errVal) {
    var msgWrapper = inputGroupWrapper.querySelector('.error-msg');
    var fieldName = inputGroupWrapper.querySelector('label').textContent;

    if (msgWrapper) {
      var errMsg = 'Fehler';

      switch (errType) {
        case 'required':
          errMsg = '"' + fieldName.replace('*', '').trim() + '" ist ein Pflichtfeld.';
          break;

        case 'pattern':
          errMsg = errVal;
          break;

        case 'minLength':
          errMsg = '"' + fieldName.replace('*', '').trim() + '" muss mindestens ' + errVal + ' Zeichen enthalten.';
          break;

        case 'maxLength':
          errMsg = '"' + fieldName.replace('*', '').trim() + '" darf maximal ' + errVal + ' Zeichen enthalten.';
          break;

        case 'password':
          errMsg = '"Passwort" muss Groß- und Kleinbuchstaben sowie eine Zahl oder ein Sonderzeichen enthalten.';
      }

      msgWrapper.innerHTML = '<span>' + errMsg + '</span>';
    }
  },
  // F**ing IE doesn't set the maxLength/minLength attribute, so we need to workaround
  getLengthAttr: function getLengthAttr(element, type) {
    var val = false;

    if (element[type + 'Length']) {
      val = element[type + 'Length'];
    } else {
      for (var i = 0; i < element.attributes.length; i++) {
        if (element.attributes[i].nodeName === type + 'length') {
          val = element.attributes[i].nodeValue;
          break;
        }
      }
    }

    return val;
  }
};
CrefoUI.Input.init();

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.Modal = {
  closeAll: function closeAll(callback, e) {
    //!e || console.dir(e)
    var modals = document.querySelectorAll('.crefo-ui-modal');

    if (modals && modals.length) {
      for (var i = 0; i < modals.length; i++) {
        var modal = modals[i];

        if (modal.dataset.noCrefoUi !== 'true') {
          modal.parentNode.removeChild(modal);
        }
      }

      setTimeout(function () {
        !callback || callback(e);
      }, 100);
    }
  },
  show: function show(opts) {
    var _this5 = this;

    if (!opts) {
      return;
    } // opts = {
    //   title: '',
    //   content: '',
    //   buttons: {
    //     confirm: '',
    //     cancel: ''
    //   },
    //   confirmCallback: function () {},
    //   cancelCallback: function () {},
    //   icon: '',
    //   dataQa: ''
    // }


    var dataQaValue = 'crefo-ui-modal';

    if (opts.dataQa && typeof opts.dataQa === 'string' && opts.dataQa.length) {
      dataQaValue += "--".concat(opts.dataQa);
    }

    var allowedIconClasses = ['success', 'error', 'info', 'warning'];
    var hasValidIconClass = opts.icon && typeof opts.icon === 'string' && allowedIconClasses.indexOf(opts.icon) !== -1;
    var newModal = document.createElement('div');
    newModal.className = 'crefo-ui-modal';

    if (hasValidIconClass) {
      newModal.className += " modal-statusicon modal-".concat(opts.icon);
    }

    newModal.setAttribute('data-qa', dataQaValue);
    newModal.addEventListener('click', function (e) {
      // close the modal when the backdrop is clicked
      if (e.target.classList.contains('crefo-ui-modal')) {
        this.closeAll(opts.cancelCallback, e);
      }
    }.bind(this));
    var modalContent = document.createElement('div');
    modalContent.className = 'modal-content';
    var modalHeader = document.createElement('div');
    modalHeader.className = 'modal-header';
    var modalClose = document.createElement('span');
    modalClose.className = 'modal-close';
    modalClose.addEventListener('click', function (e) {
      this.closeAll(opts.cancelCallback, e);
    }.bind(this));

    if (hasValidIconClass) {
      var modalHeaderIcon = document.createElement('i');
      modalHeaderIcon.className = 'crefo-ui-icon';

      if (opts.icon === 'success') {
        modalHeaderIcon.className += ' icon-circle-success';
      } else if (opts.icon === 'error') {
        modalHeaderIcon.className += ' icon-circle-error';
      } else if (opts.icon === 'info') {
        modalHeaderIcon.className += ' icon-circle-info';
      } else if (opts.icon === 'warning') {
        modalHeaderIcon.className += ' icon-circle-warning';
      }

      modalHeader.appendChild(modalHeaderIcon);
    }

    var modalHeadline = document.createElement('h2');
    modalHeadline.className = 'mt';
    modalHeadline.textContent = opts.title || '';
    var modalBody = document.createElement('div');
    modalBody.className = 'modal-body';
    modalBody.innerHTML = opts.content || '';
    modalHeader.appendChild(modalHeadline);
    modalHeader.appendChild(modalClose);
    modalContent.appendChild(modalHeader);

    if (opts.buttons && (opts.buttons.confirm || opts.buttons.cancel)) {
      var addButton = function addButton(text, cb, addMarginLeft) {
        var modalBtn = document.createElement('button');
        modalBtn.className = 'btn btn-default mt';

        if (addMarginLeft) {
          modalBtn.className += ' ml';
        }

        modalBtn.textContent = text;
        modalBtn.addEventListener('click', function (e) {
          this.closeAll(cb, e);
        }.bind(_this5));
        modalBody.appendChild(modalBtn);
      };

      if (opts.buttons.cancel && opts.buttons.cancel.length) {
        addButton(opts.buttons.cancel, opts.cancelCallback);
      }

      if (opts.buttons.confirm && opts.buttons.confirm.length) {
        var addMargin = !opts.buttons.cancel ? false : true;
        addButton(opts.buttons.confirm, opts.confirmCallback, addMargin);
      }
    }

    modalContent.appendChild(modalBody);
    newModal.appendChild(modalContent);
    this.closeAll();
    document.body.appendChild(newModal); // Modals should be closed when ESCAPE or ENTER keys are pressed

    document.addEventListener('keydown', function closeByKey(e) {
      if (e.key === "Escape" || e.key === 'Enter') {
        e.preventDefault();
        this.closeAll(opts.cancelCallback, e);
        document.removeEventListener('keydown', closeByKey);
      }
    }.bind(this));
  }
};

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.Select = {
  update: function update() {
    var elem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
    var selectWrapper;

    if (elem) {
      if (!elem.classList.contains('crefo-ui-select')) {
        while (!elem.classList.contains('crefo-ui-select')) {
          if (elem.tagName === 'BODY') {
            return;
          }

          elem = elem.parentElement;
        }
      }

      selectWrapper = [elem];
    } else {
      selectWrapper = document.getElementsByClassName('crefo-ui-select');
    }

    for (var i = 0; i < selectWrapper.length; i++) {
      var selectElem = selectWrapper[i].querySelector('select');

      if (selectElem.selectedIndex >= 0) {
        var selectedDiv = selectWrapper[i].querySelector('.select-selected');
        selectedDiv.innerHTML = selectElem.options[selectElem.selectedIndex].innerHTML;
      }

      var selectOptionsDiv = selectWrapper[i].querySelector('.select-items');

      if (selectOptionsDiv) {
        var selectOptionsDivItems = selectOptionsDiv.querySelectorAll('div[role="option"]');

        if (selectOptionsDivItems && selectOptionsDivItems.length) {
          for (var j = 0; j < selectElem.options.length; j++) {
            if (selectOptionsDivItems[j]) {
              selectOptionsDivItems[j].innerHTML = selectElem[j].innerHTML;
            }
          }
        }
      }
    }
  },
  init: function init() {
    var _this6 = this;

    var i, j, selectWrapper, selectElem, selectSelected, selectOptionsList, selectOption;
    /* Look for any elements with the class 'crefo-ui-select': */

    selectWrapper = document.getElementsByClassName('crefo-ui-select');

    for (i = 0; i < selectWrapper.length; i++) {
      if (selectWrapper[i].getAttribute('data-crefo-ui') !== 'true') {
        selectElem = selectWrapper[i].querySelector('select');
        /* For each element, create a new DIV that will act as the selected item: */

        selectSelected = document.createElement('DIV');
        selectSelected.setAttribute('class', 'select-selected');

        if (selectElem.hasAttribute('tabindex')) {
          selectSelected.setAttribute('tabindex', selectElem.getAttribute('tabindex'));
          selectSelected.tabIndex = selectElem.tabIndex;
        } else {
          selectSelected.setAttribute('tabindex', '0');
          selectSelected.tabIndex = 0;
        }

        var index = selectElem.selectedIndex < 0 ? 0 : selectElem.selectedIndex;
        selectSelected.innerHTML = selectElem.options[index].innerHTML;
        selectElem.parentElement.appendChild(selectSelected);
        /* For each element, create a new DIV that will contain the option list: */

        selectOptionsList = document.createElement('DIV');
        selectOptionsList.setAttribute('class', 'select-items select-hide');
        selectOptionsList.setAttribute('role', 'listbox');

        for (j = 0; j < selectElem.length; j++) {
          /* For each option in the original select element,
          create a new DIV that will act as an option item: */
          selectOption = document.createElement('DIV');
          selectOption.setAttribute('role', 'option');
          selectOption.innerHTML = selectElem.options[j].innerHTML;
          selectOption.addEventListener('click', function (e) {
            /* When an item is clicked, update the original select box,
            and the selected item: */
            var y, i, k, s, h;
            s = e.currentTarget.parentNode.parentNode.getElementsByTagName('select')[0];
            h = e.currentTarget.parentNode.previousSibling;

            for (i = 0; i < s.length; i++) {
              if (s.options[i].innerHTML == e.currentTarget.innerHTML) {
                s.options[i].setAttribute('selected', 'selected');
                s.selectedIndex = i;
                h.innerHTML = e.currentTarget.innerHTML;
                y = e.currentTarget.parentNode.getElementsByClassName('same-as-selected');

                for (k = 0; k < y.length; k++) {
                  y[k].removeAttribute('class');
                }

                e.currentTarget.setAttribute('class', 'same-as-selected');
                setTimeout(function () {
                  this.closeAllSelects();
                }.bind(this), 0); //break
              } else {
                s.options[i].removeAttribute('selected');
              }
            } // trigger a change event on the original select field


            var changeEvent = null;

            if (typeof Event === 'function') {
              changeEvent = new Event('change');
            } else if (typeof document.createEvent === 'function' && typeof window.Event !== 'function') {
              changeEvent = document.createEvent('Event');
              changeEvent.initEvent('change', false, false);
            }

            if (changeEvent !== null) {
              s.dispatchEvent(changeEvent);
            }

            h.click();
          }.bind(this));
          selectOptionsList.appendChild(selectOption);
        }

        selectElem.parentElement.appendChild(selectOptionsList);
        selectElem.style.display = 'none';
        selectElem.parentElement.addEventListener('click', function (e) {
          _this6.onOpenSelectList(e);
        });
        selectSelected.addEventListener('keydown', function (e) {
          /* Allow opening of select box by key, if it has "focus" */
          var elem = e.target;

          if (!elem.classList.contains('select-selected')) {
            while (!elem.classList.contains('select-selected')) {
              if (elem.tagName === 'BODY') {
                return;
              }

              elem = elem.parentElement;
            }
          }

          if (!elem.classList.contains('select-arrow-active')) {
            _this6.onOpenSelectList(e);
          }
        });
        selectWrapper[i].setAttribute('data-crefo-ui', 'true');
      }
    }
  },
  onOpenSelectList: function onOpenSelectList(e, selectSelected) {
    var elem = e.target;

    if (!elem.classList.contains('input-wrapper')) {
      while (!elem.classList.contains('input-wrapper')) {
        if (elem.tagName === 'BODY') {
          return;
        }

        elem = elem.parentElement;
      }
    }
    /* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */


    e.stopPropagation();
    this.closeAllOtherSelects(elem.querySelector('.select-selected'));
    this.update(elem);
    elem.classList.toggle('show-options');
    elem.querySelector('.select-items').classList.toggle('select-hide');
    elem.querySelector('.select-selected').classList.toggle('select-arrow-active');
    /* If the user clicks anywhere outside the select box,
    then close all select boxes: */

    document.addEventListener('click', function closeSelects() {
      this.closeAllSelects();
      document.removeEventListener('click', closeSelects);
    }.bind(this));
  },
  closeAllSelects: function closeAllSelects() {
    var selectWrapper = document.querySelectorAll('.show-options');
    var selectSelected = document.querySelectorAll('.select-selected');
    var selectLists = document.querySelectorAll('.select-items');

    for (var i = 0; i < selectWrapper.length; i++) {
      selectWrapper[i].classList.remove('show-options');
    }

    for (var j = 0; j < selectSelected.length; j++) {
      selectSelected[j].classList.remove('select-arrow-active');
    }

    for (var k = 0; k < selectLists.length; k++) {
      selectLists[k].classList.add('select-hide');
    }
  },
  closeAllOtherSelects: function closeAllOtherSelects(element) {
    /* A function that will close all select boxes in the document,
    except the current select box: */
    var x, y, i;
    var arrNo = [];
    x = document.getElementsByClassName('select-items');
    y = document.getElementsByClassName('select-selected');

    for (i = 0; i < y.length; i++) {
      if (element === y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove('select-arrow-active');
      }
    }

    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add('select-hide');
      }
    }
  }
};
CrefoUI.Select.init();

if (!window.CrefoUI) {
  var CrefoUI = {};
  window.CrefoUI = CrefoUI;
}

CrefoUI.Tooltips = {
  init: function init() {
    var tooltipWrappers = document.querySelectorAll('.crefo-ui-tooltip');

    if (tooltipWrappers && tooltipWrappers.length) {
      for (var i = 0; i < tooltipWrappers.length; i++) {
        if (tooltipWrappers[i].getAttribute('data-crefo-ui') !== 'true') {
          var tooltipWrapper = tooltipWrappers[i];
          var text = tooltipWrapper.getAttribute('data-tooltip-text');

          if (!tooltipWrapper.querySelector('.crefo-tooltip')) {
            var $tooltip = document.createElement('span');
            $tooltip.className = 'crefo-tooltip';
            $tooltip.textContent = text || ''; // to position the tooltip correctly, we need to wrap content
            // in non-inline elements within an inline element

            var inlineWrapper = document.createElement('span');
            inlineWrapper.className = 'inline-wrapper';
            inlineWrapper.innerHTML = tooltipWrapper.innerHTML;
            inlineWrapper.addEventListener('mouseover', function (e) {
              var elem = e.target;

              if (!elem.classList.contains('inline-wrapper')) {
                while (!elem.classList.contains('inline-wrapper')) {
                  elem = elem.parentElement;
                }
              } // check if we have enough space to display the tooltip as defined,
              // otherwise reposition as space is available


              var rect = elem.getBoundingClientRect();
              var availableSpace = {
                left: rect.left,
                right: document.body.offsetWidth - (rect.left + rect.width)
              };
              var positionOverwritten = false;

              if (elem.parentElement.classList.contains('tooltip-left') && availableSpace.left < 150) {
                elem.parentElement.classList.remove('tooltip-left');
                elem.parentElement.classList.add('tooltip-right');
                positionOverwritten = true;
              }

              if (elem.parentElement.classList.contains('tooltip-right') && availableSpace.right < 150) {
                elem.parentElement.classList.remove('tooltip-right');

                if (!positionOverwritten) {
                  elem.parentElement.classList.add('tooltip-left');
                } else {
                  elem.parentElement.classList.add('tooltip-top');
                }
              }

              elem.classList.add('active');
            });
            inlineWrapper.addEventListener('mouseout', function (e) {
              var elem = e.target;

              if (!elem.classList.contains('inline-wrapper')) {
                while (!elem.classList.contains('inline-wrapper')) {
                  elem = elem.parentElement;
                }
              }

              elem.classList.remove('active');
            });
            inlineWrapper.appendChild($tooltip);
            tooltipWrapper.innerHTML = '';
            tooltipWrapper.appendChild(inlineWrapper);
          }

          tooltipWrappers[i].setAttribute('data-crefo-ui', 'true');
        }
      }
    }
  }
};
CrefoUI.Tooltips.init();
