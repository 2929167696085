
/**
 * Update state about fetching feature.
 *
 * @param {FeatureStates} state
 */
 export function GET_FEATURE_DRD(state) {
    state.drd.fetching = true
}

/**
 * Update state about fetched feature.
 *
 * @param {FeatureStates} state
 * @param {boolean} featureActive
 */
export function RECEIVE_FEATURE_DRD(state, featureActive) {
    state.drd.fetching = false
    state.drd.fetched = true
    state.drd.active = featureActive === true
    state.drd.error = false
    state.drd.errorData = null
}

/**
 * Update state about error while fetching feature.
 *
 * @param {FeatureStates} state
 * @param {FeatureError} errorData
 */
export function ERROR_FEATURE_DRD(state, errorData) {
    state.drd.fetching = false
    state.drd.fetched = false
    state.drd.active = false
    state.drd.error = true
    state.drd.errorData = errorData
}

/**
 * Update state about fetching feature.
 *
 * @param {FeatureStates} state
 */
export function GET_FEATURES_MONOREPO(state) {
    state.monorepo.fetching = true
}

/**
 * Update state about fetched feature.
 *
 * @param {FeatureStates} state
 * @param {Object} featuresActive
 * @property {boolean} featuresActive.askNotificationActive
 * @property {boolean} featuresActive.portfolioViewActive
 * @property {boolean} featuresActive.submitCollectionFileInfoActive
 * @property {boolean} featuresActive.wkvActive
 * @property {boolean} featuresActive.wkvRuVActive
 */
export function RECEIVE_FEATURES_MONOREPO(state, featuresActive) {
    state.monorepo.fetching = false
    state.monorepo.fetched = true
    for (const feature in state.monorepo.active) {
        state.monorepo.active[feature] = featuresActive[feature] === true
    }
    state.monorepo.error = false
    state.monorepo.errorData = null
}

/**
 * Update state about error while fetching feature.
 *
 * @param {FeatureStates} state
 * @param {FeatureError} errorData
 */
export function ERROR_FEATURES_MONOREPO(state, errorData) {
    state.monorepo.fetching = false
    state.monorepo.fetched = false
    for (const feature in state.monorepo.active) {
        state.monorepo.active[feature] = false
    }
    state.monorepo.error = true
    state.monorepo.errorData = errorData
}
