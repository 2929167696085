import './crefo-ui.bundle.js'

const UIPlugin = {
  install (Vue, options) {
    window.__MC_VUE_VERSION = Vue.version

    Vue.mixin({
      created: function () {
        for (let key in CrefoUI) {
          if (typeof CrefoUI[key].init === 'function') {
            CrefoUI[key].init();
          }
        }
       /* CrefoUI.Accordion.init()
        CrefoUI.ContentTabs.init()
        CrefoUI.Header.init()
        CrefoUI.Input.init()
        CrefoUI.Select.init()
        CrefoUI.Tooltips.init()*/

      },
      updated: function () {
        for (let key in CrefoUI) {
          if (typeof CrefoUI[key].init === 'function') {
            CrefoUI[key].init();
          }
          if (typeof CrefoUI[key].update === 'function') {
            CrefoUI[key].update();
          }
        }
        /*CrefoUI.Accordion.init()
        CrefoUI.ContentTabs.init()
        CrefoUI.Header.init(false)
        CrefoUI.Input.init()
        CrefoUI.Select.init()
        CrefoUI.Select.update()
        CrefoUI.Tooltips.init()*/
      }
    })
  }
}

export default UIPlugin
