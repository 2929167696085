<template>
    <nav v-if="isAuthenticated && isMitgliedsbenutzer" class='sidebar-navigation'>
        <ul class='sidebar-navigation__list'>
            <li
                class='sidebar-navigation__item'
                v-for="item in navItems"
                v-if="!item.requireSuperUser || isSuperUser"
                :key="item.icon"
            >
                <span v-if="!item.isRouterLink">
                    <a
                        :data-qa="item.data.qa"
                        class='sidebar-navigation__link'
                        v-bind="{ 'href': setHrefLinkByLanguage(item) }"
                        :class="{'sidebar-navigation__item--selected': activeSidebarItem(item)}"
                    >
                        <CrefoSidebarNavigationLinkInner
                            :item="item"
                            :unread-messages-count="unreadMessagesCount"
                            :watchlist-count="watchlistCount"
                        />
                    </a>
                </span>
                <span v-else>
                    <a
                        :data-qa="item.data.qa"
                        class='sidebar-navigation__link'
                        v-bind="{ 'href': setHrefLinkByLanguage(item) }"
                        :class="{'sidebar-navigation__item--selected': activeSidebarItem(item)}"
                        @click.prevent="routeTo(item)"
                    >
                        <CrefoSidebarNavigationLinkInner
                            :item="item"
                            :unread-messages-count="unreadMessagesCount"
                            :watchlist-count="watchlistCount"
                        />
                    </a>
                </span>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapState } from 'vuex'
import CrefoSidebarNavigationLinkInner from "@/components/navigation/partials/CrefoSidebarNavigationLinkInner.vue";
import {dispatchEvent} from "@/helper/polyfill";

export default {
    name: 'CrefoSidebarNavigation',
    components: {
        CrefoSidebarNavigationLinkInner
    },
    data () {
        return {
            navItems: [
                {
                    icon: "crefo-ui-icon icon-home",
                    isRouterLink: false,
                    data: {
                        title: "featurenav.home",
                        qa: "sideNavigation-1",
                        href: {
                            de: "/",
                            at: "/de-at/",
                            en: "/en/",
                            fr: "/fr/",
                            lu: "/fr-lu/",
                        }
                    }
                },
                {
                    icon: "crefo-brand-icon icon-envelope",
                    isRouterLink: true,
                    data: {
                        title: "featurenav.inbox",
                        qa: "sideNavigation-4",
                        href: {
                            de: "/portal/posteingang/",
                            at: "/portal/posteingang/",
                            en: "/portal/posteingang/",
                            fr: "/portal/posteingang/",
                            lu: "/portal/posteingang/",
                        }
                    }
                },
                {
                    icon: "crefo-brand-icon icon-file-text",
                    isRouterLink: false,
                    data: {
                        title: "featurenav.transactions",
                        qa: "sideNavigation-5",
                        href: {
                            de: "/vorgaenge",
                            at: "/de-at/vorgaenge",
                            en: "/en/files/",
                            fr: "/fr/operations/",
                            lu: "/fr-lu/operations/",
                        }
                    }
                },
                {
                    icon: "crefo-ui-icon icon-watchlist",
                    isRouterLink: false,
                    data: {
                        title: "featurenav.watchlist",
                        qa: "sideNavigation-29",
                        href: {
                            de: "/bonitaetsauskunft/watchlist-unternehmen",
                            at: "/de-at/bonitaetsauskunft/watchlist-unternehmen/",
                            en: "/en/business-reports/watchlist/",
                            fr: "/fr/solvabilite/watchlist-entreprises/",
                            lu: "/fr-lu/solvabilite/watchlist-entreprises/"
                        }
                    }
                },
                {
                    icon: "crefo-ui-icon icon-users-settings",
                    isRouterLink: false,
                    requireSuperUser: true,
                    data: {
                        title: "featurenav.memberadministration",
                        qa: "sideNavigation-229",
                        href: {
                            de: "/nutzerverwaltung/",
                            at: "/de-at/nutzerverwaltung/",
                            en: "/en/user-administration/",
                            fr: "/fr/gestion-des-utilisateurs/",
                            lu: "/fr-lu/gestion-des-utilisateurs/"
                        }
                    }
                }
            ]
        }
    },
    computed: {
        ...mapState([
            'userModule'
        ]),
        activeSidebarItem () {
            return item => {
                let hrefPath = this.setHrefLinkByLanguage(item)
                return hrefPath === this.$route.path
            }
        },
        setRouterLinkByLanguage() {
            return (subitem) => {

                if(this.$i18n.locale === 'en_US') {
                    return subitem.data.href.en
                } else if(this.$i18n.locale === 'de_AT') {
                    return subitem.data.href.at
                } else if(subitem.data.href.fr && this.$i18n.locale === 'fr_FR') {
                    return subitem.data.href.fr
                } else if(subitem.data.href.lu && this.$i18n.locale === 'fr_LU') {
                    return subitem.data.href.lu
                } else {
                    return subitem.data.href.de
                }
            }
            // Backup falls Probleme auftreten
            // $i18n.locale === 'en_US' ? subitem.href.en : (subitem.href.at && $i18n.locale === 'de_AT' ? subitem.href.at : subitem.href.de)
        },
        setHrefLinkByLanguage() {
            return (subitem) => {
                if(this.$i18n.locale === 'en_US') {
                    return subitem.data.href.en
                } else if(this.$i18n.locale === 'de_AT') {
                    return (subitem.data.href.at ? subitem.data.href.at : '/de-at' + subitem.data.href.de)
                } else if(subitem.data.href.fr && this.$i18n.locale === 'fr_FR') {
                    return subitem.data.href.fr
                } else if(subitem.data.href.lu && this.$i18n.locale === 'fr_LU') {
                    return subitem.data.href.lu
                } else {
                    return subitem.data.href.de
                }
            }
            // Backup falls Probleme auftreten
            //$i18n.locale === 'en_US' ? subitem.href.en : ($i18n.locale === 'de_AT' ? (subitem.href.at ? subitem.href.at : '/de-at' + subitem.href.de) : subitem.href.de)
        },
        isSuperUser: function () {
            let roles = this.$store.state.userModule.user.user.user_roles
            for (let i in roles) {
                if (roles[i].indexOf('CR-CP.BENUTZERVERWALTUNG-MITGLIED') !== -1) {
                    return true
                }
            }

            return false
        },
        isAuthenticated: function () {
            return this.$store.state.userModule.user.authenticated
        },
        isMitgliedsbenutzer: function () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        },
        memberId: function () {
            return this.$store.state.userModule.user.user.membernumber
        },
        watchlistCount: function () {
            return this.$store.state.navModule.nav.watchlistCount
        },
        unreadMessagesCount: function () {
            return this.$store.state.navModule.nav.unreadMessagesCount
        }
    },
    methods: {
        differentContextActive: function () {
            let originalMemberId = sessionStorage.getItem('originalMemberId')
            let actForMemberId = sessionStorage.getItem('actForMemberId')
            return (originalMemberId && actForMemberId && originalMemberId !== actForMemberId) || false;
        },
        routeTo (item) {
            let href = this.setHrefLinkByLanguage(item)
            if (href === this.$route.path && item.data.title === 'featurenav.inbox') {
                dispatchEvent('reloadPosteingang')
            } else {
                this.$router.push(href)
            }
        },
        fetchData () {
            if (this.isMitgliedsbenutzer) {
                if (!this.differentContextActive()) {
                    this.$store.dispatch('navModule/setWatchlistCount')
                }
                this.$store.dispatch('navModule/setUnreadMessagesCount')
            }
        }
    },
    mounted () {
        this.$store.watch((state) => state.userModule.user.authenticated, (newProps, oldProps) => { 
            if (newProps) {
                this.fetchData()
            }
        })
        this.$store.watch((state) => state.userModule.user.user.actForMemberId, (newProps, oldProps) => {
            this.fetchData()
        })
    }
}
</script>

<style scoped>
.sidebar-navigation {
    position: fixed;
    top: 230px;
    left: 0;
    z-index: 10;
}
.sidebar-navigation__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.sidebar-navigation__item {
    position: relative;
    display: block;
    background-color: #009ee3;
    cursor: pointer;
    width: 80px;
    height: 80px;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.125);
    box-shadow: 0 0 10px rgba(0,0,0,.125);
}
.sidebar-navigation__item+.sidebar-navigation__item {
    margin-top: 4px;
}
.sidebar-navigation__item--selected {
    background-color: #004884;
}
.sidebar-navigation__item:hover {
    background-color: #004884;
}

.sidebar-navigation__link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 80px;
    height: 80px;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 767px) {
    .sidebar-navigation {
        display: none !important;
    }
}
</style>
