<template>
    <header class="header-nav scrollspy has-mainmenu has-servicemenu" data-qa="header-content">
        <!-- BEGIN main header row -->
        <div class="header-primary">
            <div class="container">
                <div class="row">
                    <div class="col-12 flex-header">
                        <!-- Home logo -->
                        <MainLogo />

                        <!-- Service Menu -->
                        <ServiceMenu />

                        <!-- Language Switch -->
                        <LanguageSwitch />

                        <!-- Profile Menu -->
                        <ProfileMenu />

                        <!-- Mobile Search -->
                        <MobileSearch v-if="isMitgliedsbenutzer" />

                        <!-- Mobile Login Button -->
                        <MobileLoginButton
                            v-if="!isAuthenticated"
                            @click.native="login"
                        />
                        <!-- Mobile Navigation Opener (aka Hamburger) -->
                        <MobileMenuButton v-else />
                    </div>
                </div>
            </div>
        </div> <!-- END main header row -->

        <!-- BEGIN secondary header row -->
        <div class="header-secondary" v-if="isAuthenticated">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Main Menu (Desktop & Tablet) -->
                        <MainMenu />

                        <!-- Search -->
                        <Search v-if="isMitgliedsbenutzer" />
                    </div>
                </div>
            </div>
        </div> <!-- END secondary header row -->

        <!-- Mobile Navigation Menu -->
        <MobileMenu />
    </header>
</template>

<script>
import MainLogo from '@/components/navigation/partials/MainLogo'
import MainMenu from '@/components/navigation/partials/MainMenu'
import MobileMenu from '@/components/navigation/partials/MobileMenu'
import ProfileMenu from '@/components/navigation/partials/ProfileMenu'
import ServiceMenu from '@/components/navigation/partials/ServiceMenu'
import Search from '@/components/navigation/partials/Search'
import MobileSearch from '@/components/navigation/partials/MobileSearch'
import LanguageSwitch from '@/components/navigation/partials/LanguageSwitch'
import MobileMenuButton from '@/components/navigation/partials/MobileMenuButton'
import MobileLoginButton from '@/components/navigation/partials/MobileLoginButton'

import axios from 'axios'
import Keycloak from 'keycloak-js'
import { getKeycloakEnvConfigAndExecute } from '@/keycloak-config'
import { mapState } from 'vuex'
import storage from 'localstorage-with-ttl'
import { dispatchEvent } from '@/helper/polyfill'

export default {
    name: 'CrefoDefaultNavigation',
    components: {
        MainLogo,
        MainMenu,
        MobileMenu,
        ProfileMenu,
        ServiceMenu,
        Search,
        MobileSearch,
        LanguageSwitch,
        MobileMenuButton,
        MobileLoginButton
    },
    data () {
        return {
            tokenUpdateInterval: null
        }
    },
    computed: {
        ...mapState([
            'navModule',
            'userModule',
            'featureModule'
        ]),
        isAuthenticated: function () {
            return this.$store.state.userModule.user.authenticated
        },
        isMitgliedsbenutzer: function () {
            return this.$store.state.userModule.user.user.isMitgliedsbenutzer
        }
    },
    methods: {
        userHasRole (roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(roleName)
        },
        userHasOneOfRoles (roleNames) {
            return this.$store.state.userModule.user.user.user_roles.some(role => roleNames.includes(role))
        },
        getFeatureToggles () {
            if (this.isMitgliedsbenutzer) {
                this.$store.dispatch('featureModule/getFeaturesMonorepo', storage)
            }
            this.$store.dispatch('featureModule/getFeatureDrd', storage)
        },
        checkStorage () {
            const uid = btoa('mc_' + this.$store.state.userModule.user.user.id).toString()
            if (!storage.has('uid') || storage.get('uid') !== uid) {
                this.clearStorage()
                storage.set('uid', uid)
            }
        },
        // logout () {
        //     window.keycloakStatusChecked = false

        //     const doLogout = (config) => {
        //         if (this.tokenUpdateInterval !== null) {
        //             window.clearInterval(this.tokenUpdateInterval)
        //         }

        //         sessionStorage.removeItem("actForMemberId")
        //         sessionStorage.removeItem("actForMemberName")
        //         sessionStorage.removeItem("originalMemberId")
        //         sessionStorage.removeItem("reportAct4SbNumber")
        //         sessionStorage.removeItem('skipActForMemberHeader')
        //         localStorage.removeItem("vue-token")
        //         localStorage.removeItem("vue-refresh-token")

        //         this.clearStorage()

        //         dispatchEvent('loggedOut')

        //         const keycloakExists = setInterval(() => {
        //             if (window.keycloak.token === undefined) {
        //                 clearInterval(keycloakExists)
        //                 window.location.href = this.$router.getLogoutUrl()
        //                 window.keycloakStatusChecked = true
        //             } else if (window.keycloak && window.keycloak.logout && typeof window.keycloak.logout === 'function') {
        //                 clearInterval(keycloakExists)
        //                 window.keycloak.logout({ redirectUri: this.$router.getLogoutUrl() })
        //                 window.setTimeout(() => {
        //                     window.keycloakStatusChecked = true
        //                 }, 1000)
        //             }
        //         }, 200)
        //     }

        //     getKeycloakEnvConfigAndExecute(doLogout)
        // },
        clearStorage () {
            storage.remove('uid')
            storage.remove('features')
            storage.remove('featureflags_monorepo')
            storage.remove('featureflag_drd')
            storage.remove('featureflag_kp')
        },
        onAuthenticated (keycloakInstance) {
            localStorage.setItem("vue-token", keycloakInstance.token)
            localStorage.setItem("vue-refresh-token", keycloakInstance.refreshToken)
            document.body.classList.add('logged-in')

            // Add a request interceptor with authentication header
            axios.interceptors.request.use(
              (config) => {
                config.headers.Authorization = 'Bearer ' + keycloakInstance.token
                return config
              },
              (error) => {
                Promise.reject(error)
              })

            console.log('[onAuth] ', keycloakInstance)

            if (keycloakInstance.idTokenParsed) {
                this.$store.dispatch('userModule/authenticated', keycloakInstance)
                this.checkStorage()
            }
            window.keycloak = keycloakInstance
            dispatchEvent('loggedIn')
            window.setTimeout(() => {
                window.keycloakStatusChecked = true
            }, 100)

            this.tokenUpdateInterval = setInterval(() => {
                console.log('[Keycloak] Try token update')
                keycloakInstance.updateToken(70).then((refreshed) => {
                    if (refreshed) {
                        console.log('[Keycloak] Token refreshed')
                        console.log('[Keycloak] Updated keycloak token: ', JSON.stringify(keycloakInstance.token))

                        localStorage.setItem("vue-token", keycloakInstance.token)

                        this.$store.dispatch('userModule/tokenUpdated', keycloakInstance)
                        this.checkStorage()

                        axios.interceptors.request.use(
                          (config) => {
                            config.headers.Authorization = 'Bearer ' + keycloakInstance.token
                            return config
                          },
                          (error) => {
                            Promise.reject(error)
                          })

                        dispatchEvent('keycloakTokenUpdated')
                    } else {
                        console.log('[Keycloak] Token not refreshed, valid for ' +
                            Math.round(keycloakInstance.tokenParsed.exp + keycloakInstance.timeSkew - new Date().getTime() / 1000) + ' seconds')
                    }
                }).catch((err) => {
                    console.log('[Keycloak] Failed to refresh token:')
                    console.log(err)
                    if (window.keycloak.token === undefined && this.isAuthenticated) {
                        this.logout()
                    }
                })
            }, 30000)
        },
        forceUpdateKeycloakToken () {
            return new Promise(async (resolve) => {
                try {
                    window.keycloak.updateToken(-1).then((refreshed) => {
                        if (refreshed) {
                            console.log('[Keycloak] Forced token refresh')
                            console.log('[Keycloak] Updated keycloak token: ', JSON.stringify(window.keycloak.token))

                            localStorage.setItem("vue-token", window.keycloak.token)

                            this.$store.dispatch('userModule/tokenUpdated', window.keycloak)
                            this.checkStorage()

                            axios.interceptors.request.use(
                              (config) => {
                                config.headers.Authorization = 'Bearer ' + window.keycloak.token
                                return config
                              },
                              (error) => {
                                Promise.reject(error)
                              })

                            dispatchEvent('keycloakTokenUpdated')

                            resolve(true)
                        }
                    }).catch((err) => {
                        console.log('[Keycloak] Failed to force refresh token:')
                        console.log(err)
                        resolve(false)
                    })
                } catch (error) {
                    resolve(false)
                }
            })
        },
        login () {
            window.keycloakStatusChecked = false

            const doLogin = (config) => {
                let keycloak = new Keycloak(config)

                keycloak.init({ pkceMethod: 'S256', onLoad: 'login-required' })
                    .then((auth) => {
                        if (!auth) {
                            console.log("[Keycloak] NOT Authenticated")
                            window.keycloakStatusChecked = true
                        } else {
                            console.log("[Keycloak] Authenticated ", keycloak.token)
                            console.dir(keycloak)

                            this.onAuthenticated(keycloak)
                        }
                    })
                    .catch((err) =>{
                        console.log("[Keycloak] Authenticated failed")
                        window.keycloakStatusChecked = true
                    })
            }

            getKeycloakEnvConfigAndExecute(doLogin)
        }
    },
    created () {
        axios.interceptors.request.use(
            (config) => {
                if (typeof window === 'object' && window !== null) {
                    if (window.keycloak && window.keycloak.token) {
                        config.headers.Authorization = 'Bearer ' + window.keycloak.token
                    }
                }
                return config
            },
            (error) => {
                Promise.reject(error)
            })
    },
    mounted () {
        if (localStorage.getItem('vue-token') !== null) {
            // Add a request interceptor
            axios.interceptors.request.use(
              (config) => {
                config.headers.Authorization = 'Bearer ' + localStorage.getItem('vue-token')
                return config
              },
              (error) => {
                Promise.reject(error)
              })
        }

        const doSilentLoginCheck = (config) => {
            let silentKeycloak = new Keycloak(config)

            silentKeycloak.init({
                pkceMethod: 'S256',
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/portal/static/silent-check-sso.html'
            }).then((isLoggedIn) => {
                if (isLoggedIn === true) {
                    console.log("[Keycloak] Authenticated ", silentKeycloak.token)
                    console.dir(silentKeycloak)
                    this.onAuthenticated(silentKeycloak)
                } else {
                    window.keycloakStatusChecked = true
                }
            }).catch((err) => {
                console.log(err)
                window.keycloakStatusChecked = true
            })
        }

        getKeycloakEnvConfigAndExecute(doSilentLoginCheck)

        if (this.$store.state.userModule.user.authenticated) {
            this.getFeatureToggles()
            this.$store.dispatch('userModule/getUserdata')
        } else {
            this.$store.watch((state) => state.userModule.user.authenticated, (newProps, oldProps) => {
                if (newProps) {
                    this.getFeatureToggles()
                    this.$store.dispatch('userModule/getUserdata')
                }
            })
        }
    },
    beforeCreate () {
        window.addEventListener('redirectToLogin', () => {
            console.log('caught redirectToLogin event')
            this.login()
        }, false)

        window.addEventListener('redirectToLogout', () => {
            console.log('caught redirectToLogout event')
            this.logout()
        }, false)

        window.addEventListener('updateKeycloakToken', () => {
            console.log('caught updateKeycloakToken event')
            this.forceUpdateKeycloakToken()
        }, false)

        // Don't use this function in your code, but instead use the documented
        // browser event. This is soley for use within axios' request interceptor.
        window.updateKeycloakToken = async () => {
            return new Promise(async (resolve) => {
                let updated = await this.forceUpdateKeycloakToken()
                resolve(updated)
            })
        }

        window.isAuthenticated = (forceLogin = true, loginWaitTimeout = 15000) => {
            return new Promise((resolve, reject) => {
                const checkIsAuthenticated = (timeout = 100, timeElapsed = 0) => {
                    if (window.keycloak && window.keycloak.token) {
                        resolve(window.keycloak.token)
                    } else if (window.keycloakStatusChecked === true || timeElapsed >= loginWaitTimeout) {
                        if (forceLogin) {
                            this.login()
                        }
                        reject()
                    } else {
                        timeout = Math.max(Math.min(timeout, loginWaitTimeout - timeElapsed), 1)
                        setTimeout(() => {
                            checkIsAuthenticated(timeout * 2, timeElapsed + timeout)
                        }, timeout)
                    }
                }

                checkIsAuthenticated()
            })
        }
    }
}
</script>

<style lang="less">
.header-nav {
    width: 100vw;
    max-width: 100vw;
}

.flex-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

@media only screen and (min-width: 768px) {
    .header-nav.scrollspy-fixed .header-primary {
        display: none !important;
    }
    .header-nav .header-secondary {
        display: block !important;
    }
    .header-nav a.mobile-main-menu-opener {
        display: none !important;
    }
}
</style>
