<template></template>
<script>
export default {
    name: "SuperUserMessage",
    data() {
        return {};
    },
    computed: {
        memberStructure() {
            return this.$store.state.userModule.user.user.memberStructure
        }
    },
    methods: {
        amISuperSuperUser(user, memberStructure) {            
            
            // 1.) Check if we have a CTV member structure
            if (
                !memberStructure ||
                !memberStructure.mainMember ||
                !memberStructure.subMembers ||
                !memberStructure.subMembers.length
            ) {
                return false
            }
            

            // 2.) Check if the current user's memberId exists in the given CTV member structure
            // @TODO: Currently, the logged-in user must be a user of the member structure's
            // main member. Originally, the feature was planned to allow all SuperUsers of the
            // member structure's main member. If this logic will be added (again), just uncomment
            // the else-block.
            let currentMemberExistsInStructure = false
            if (user.membernumber === memberStructure.mainMember.memberId) {              
                currentMemberExistsInStructure = true
            }
            // 3.) Check if the current user is super user for the given CTV member structure's main member
            let currentUserisSuperUserForMainMember = false;
            if (currentMemberExistsInStructure) {
                let roles = user.user_roles
            
                for (let i in roles) {
                    if (
                        roles[i].match(
                            'CR-CP.BENUTZERVERWALTUNG-MITGLIED'
                        ) &&
                        roles[i].match(memberStructure.mainMember.memberId)
                    ) {
                        currentUserisSuperUserForMainMember = true;
                    }
                }
            }

            return currentMemberExistsInStructure && currentUserisSuperUserForMainMember ? true : false
        },
        /**
         * getStoredData - get the data from specific session
         *
         * @returns {Object} - json parsed date from session
         */
        getStoredData() {
            return typeof localStorage.getItem("MEMBERSTRUCTURE_LAST_UPDATE") === 'string' ? parseInt(localStorage.getItem("MEMBERSTRUCTURE_LAST_UPDATE")) : localStorage.getItem("MEMBERSTRUCTURE_LAST_UPDATE")
        },

        /**
         * getLiveData - get the data of live memberStructure
         *
         * @returns {Array} -  returns an array of memberStructure objects
         */
        getLiveData() {
            return this.extractEssentialData(this.memberStructure)
        },

        /**
         * storeData () - stores the data in specific session
         *
         * @param {Object} memberStructure - memberStructure object
         * @returns {boolean} - true | false
         */
        storeData(memberStructure) {
            localStorage.setItem(
                "MEMBERSTRUCTURE_LAST_UPDATE",
                this.extractEssentialData(memberStructure)
            );
            return true;
        },

        /**
         * hasStoredData () - checks if there is session data
         */
        hasStoredData() {
            return new Promise((resolve, reject) => {
                let sessionData = this.getStoredData();
                resolve(sessionData !== null);
            });
        },
        /**
         * extractEssentialData () - extracting only necessary data
         *
         * @param {Object} memberStructure - memberStructure object
         * @returns {Object} - object of necessary data
         */
        extractEssentialData(memberStructure) {
            return parseInt(memberStructure.lastUpdateTime)
        },

        /**
         * dataHasChanged () - checks if changes are made to the memberStructure
         *
         * @returns {boolean} - true | false
         */
        dataHasChanged() {            
            return !this.deepEqual(this.getLiveData(), this.getStoredData())
                ? true
                : false;
        },
        userHasRole (roleName) {
            return this.$store.state.userModule.user.user.user_roles.includes(roleName)
        },
        deepEqual(x, y) {
            const ok = Object.keys, tx = typeof x, ty = typeof y
            return x && y && tx === 'object' && tx === ty ? (
                ok(x).length === ok(y).length &&
                ok(x).every(key => deepEqual(x[key], y[key]))
            ) : (x === y)
        },
        showDialog (type) {
            const modalCallback = () => {
                this.storeData(this.memberStructure)
            };

            // // single object or array of objects
            // const textLinkCallbackArray = {
            //     element: '#changedMemberstructure',
            //     callback: () => {
            //         sessionStorage.removeItem('actForMemberId')
            //         sessionStorage.removeItem('reportAct4SbNumber')
            //         this.storeData(this.memberStructure)
            //         window.location.href = this.$t('menu.profilemenu.administration.link')
            //     } 
            // };

            window.textCallback = () => {
                sessionStorage.removeItem('actForMemberId')
                sessionStorage.removeItem('reportAct4SbNumber')
                this.storeData(this.memberStructure)
                window.location.href = this.$t('menu.profilemenu.administration.link')
                return void(0)
            }

            const text = {
                'init': {
                    header: this.$t('memberstructure.dialog.message.init.headline'),
                    text: this.$t('memberstructure.dialog.message.init.text', { link: `<a id="changedMemberstructure" data-qa="memberstructure-changes-administration-link" href="javascript: window.textCallback();">${this.$t('memberstructure.dialog.message.init.linktext')}</a>`})
                },
                'change': {
                    header: this.$t('memberstructure.dialog.message.init.headline'),
                    text: this.$t('memberstructure.dialog.message.change.text', { link: `<a id="changedMemberstructure" data-qa="memberstructure-changes-administration-link" href="javascript: window.textCallback();">${this.$t('memberstructure.dialog.message.change.linktext')}</a>`})
                }
            };

            window.CrefoUI.Modal.show({
                title: text[type].header,
                content: text[type].text,
                icon: "info",
                dataQa: `memberStructure-${type}-dialog`,
                buttons: {
                    confirm: this.$t("shared.modals.ok"),
                },
                confirmCallback: modalCallback,
                cancelCallback: modalCallback,
                // textLinkCallbackArray: textLinkCallbackArray
            })
        }
    },
    mounted() {
        this.$store.watch(
            (state) => state.userModule.user.user.memberStructure,
            (newProps, oldProps) => {
                if (newProps) {
                    
                    if (
                        this.amISuperSuperUser(
                            this.$store.state.userModule.user.user,
                            newProps
                        )
                    ) {                        
                        this.hasStoredData().then((value) => {
                            if (!value) {
                                this.storeData(newProps);
                                this.showDialog("init");
                                console.log(
                                    "Logger -> FILE: vue/src/components/navigation/partials/ProfileMenu.vue -> LINE: 408 -> init"
                                );
                            } else {
                                if (this.dataHasChanged()) {
                                    this.showDialog("change")
                                    console.log(
                                        "Logger -> FILE: vue/src/components/navigation/partials/ProfileMenu.vue -> LINE: 411 -> change"
                                    )
                                } else {
                                    console.log(
                                        "Logger -> FILE: vue/src/components/navigation/partials/ProfileMenu.vue -> LINE: 414 -> no change"
                                    )
                                }
                            }
                        });
                    }
                }
            }
        );
    },
};
</script>
<style></style>
