export default {
    isActive: false,
    init () {
        if (!this.isActive && isSEDEV() && !isDevOrTestEnv() && isSelenium()) {
            this.isActive = true
            addGlobalEventHandler()
            console.log('Selenium detected.')
            const now = new Date()
            let nowTimeString = now.toLocaleTimeString()
            nowTimeString += '.' + now.getMilliseconds()
            console.log(`Starting with Url (${nowTimeString}): ${location.href}`)
        }
    }
}

function isSEDEV () {
    return location.host.indexOf('sedevcp.') === 0
}

function isDevOrTestEnv () {
    return process && process.env && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
}

function isSelenium () {
    if (navigator && navigator.userAgent && navigator.userAgent.toLowerCase().indexOf('headless') !== -1) {
        return true
    }
    if (localStorage && localStorage.getItem('selenium')) {
        return true
    }

    return false
}

function addGlobalEventHandler () {
    document.addEventListener('mouseup', globalEventHandler, false)
    document.addEventListener('change', globalEventHandler, false)
    document.addEventListener('select', globalEventHandler, false)
    document.addEventListener('focusin', globalEventHandler, false)
}

function removeGlobalEventHandler () {
    document.removeEventListener('mouseup', globalEventHandler, false)
    document.removeEventListener('change', globalEventHandler, false)
    document.removeEventListener('select', globalEventHandler, false)
    document.removeEventListener('focusin', globalEventHandler, false)
}

function globalEventHandler (event) {
    const eventTime = new Date()
    let eventTimeString = eventTime.toLocaleTimeString()
    eventTimeString += '.' + eventTime.getMilliseconds()
    let eventInfo = 'Event: (' + eventTimeString + ') '

    if (event.type && event.type.length) {
        eventInfo += event.type.toUpperCase() + ' on '
    }

    console.log(eventInfo + getHtmlElementInfo(event.target))
}

function getHtmlElementInfo(element, depth = 3) {
    let elementInfo = ''

    if (typeof element !== 'object' || element === null) {
        return elementInfo
    }
    if (!(element instanceof HTMLElement)) {
        return elementInfo
    }

    if (element.tagName && element.tagName.length) {
        elementInfo += element.tagName
    } else if (element.nodeType && element.nodeType > 0) {
        elementInfo += 'NODE_TYPE_' + element.nodeType
    }
    if (element.id && element.id.length) {
        elementInfo += '#' + element.id
    }
    if (element.className && element.className.length) {
        elementInfo += '.' + element.className.substr(0, 100)
        if (element.className.length > 100) {
            elementInfo += '...'
        }
    }
    const data = element.getAttribute('data-qa')
    if (data && data.length) {
        elementInfo += '[data-qa="' + data + '"]'
    }
    if (element.name && element.name.length) {
        elementInfo += '[name="' + element.name + '"]'
    }
    if (depth > 2) {
        if (element.value && element.value.length) {
            elementInfo += '[value="' + element.value.substr(0, 20) + '"]'
        }
        if (element.selectedIndex && element.selectedIndex >= 0) {
            elementInfo += '[selectedIndex="' + element.selectedIndex + '"]'
        }
        if (element.innerText && element.innerText.length) {
            elementInfo += '<~"' + element.innerText.substr(0, 20)
                .replace("\n", ' ')
                .replace("\r", '')
                .replace("\t", '')
            if (element.innerText.length > 20) {
                elementInfo += '...'
            }
            elementInfo += '">'
        }
    }

    if (depth > 0) {
        const parentElementInfo = getHtmlElementInfo(element.parentElement, depth - 1)
        if (parentElementInfo.length) {
            elementInfo = parentElementInfo + ' > ' + elementInfo
        }
    }

    return elementInfo
}
