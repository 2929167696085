<template>
    <a
        v-else
        href="javascript:void(0)"
        class="mobile-main-menu-opener"
        @click="toggleMobileNavigation"
    >
        <i
            class="crefo-ui-icon icon-color-blue"
            :class="{ 'icon-mobile-menu': !mobileOpen, 'icon-close': mobileOpen }"
            aria-hidden="true"
        />
    </a>
</template>

<script>
export default {
    name: 'MobileMenuButton',
    data () {
        return {
            mobileOpen: false
        }
    },
    methods: {
        toggleMobileNavigation () {
            this.$store.dispatch('navModule/closeAllNavMenus')
            this.$store.dispatch('navModule/toggleMobileNavigation', !this.mobileOpen)
            document.body.classList.toggle('mobile-menu-opened')
        }
    },
    mounted () {
        this.$store.watch((state) => state.navModule.nav.mobileOpen, (newProps, oldProps) => {
            this.mobileOpen = newProps
        })
    }
}
</script>

<style lang="less">
</style>
