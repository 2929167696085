import axios from 'axios'

function getKeycloakEnvConfigAndExecute(callback) {
    const KC_CONF = {
        url: 'https://login.creditreform.de/auth', // default AMS auth url
        realm: 'credref',
        clientId: 'CR-CP'
    }

    let APP_BASE = '/portal/static/'
    let file = `${APP_BASE}keycloak.json`

    if (process.env.NODE_ENV === 'development') {
        APP_BASE = '/static/'
        file = `${APP_BASE}keycloak.json.template`
        KC_CONF.url = 'https://sedevsso.login.creditreform.de/auth'
    }

    axios.get(file).then((result) => {
        if (result.status === 200) {
            if (result.data.AMS_AUTH_URL_SECURED) {
                KC_CONF.url = result.data.AMS_AUTH_URL_SECURED
            }
        }
        callback(KC_CONF)
    }).catch(() => {
        callback(KC_CONF)
    })
}

function getUserManagementSelfcareUrl() {
    return new Promise((resolve) => {
        const transform = (keycloakConfig) => {
            let baseUrl = keycloakConfig.url.replace('/auth', '')
            resolve(baseUrl + '/benutzerverwaltung/konto')
        }

        getKeycloakEnvConfigAndExecute(transform)
    })
}

export { getKeycloakEnvConfigAndExecute, getUserManagementSelfcareUrl }
