<template>
    <div
        v-if="isAuthenticated"
        class="mobile-search"
        :class="{'active': isOpen}"
    >
        <Search :is-mobile="true" />
    </div>
</template>

<script>
import Search from './Search'

export default {
    name: 'MobileSearch',
    components: {
        Search
    },
    data () {
        return {
            isOpen: false
        }
    },
    computed: {
        isAuthenticated: function () {
            return this.$store.state.userModule.user.authenticated
        }
    },
    mounted () {
        this.$store.watch((state) => state.navModule.nav.searchOpen, (newProps, oldProps) => {
            this.isOpen = newProps
        })
    }
}
</script>

<style lang="less">
.mobile-search {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 53px;
    width: 53px;
    height: 50px;
    background: #fff;
    transition: width .5s cubic-bezier(.59,.03,.17,1.37) 0s;

    &.active {
        width: calc(100% - 53px);
    }

    .search-container {
        left: 0;
        top: -50px;
        top: 0;
        width: 100%;

        &.active {
            top: 0;
            width: 100%;
        }

        .search-form {
            width: calc(100% - 50px);

            input {
                width: calc(100% - 20px);
                padding: 0 15px;
            }
        }

        .search-closer {
            width: 20px;
        }
    }
}
@media only screen and (min-width: 768px) {
    .mobile-search {
        display: none;
    }
}
</style>
